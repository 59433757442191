<template>
  <el-card>
    <div style="max-width: 30vw">
      <b>{{ tooltipData.Question }}</b>

      <div>
        <svg height="12" width="12" style="display: inline-block">
          <circle
            v-if="tooltipData['Is expected?'] == 'Yes'"
            cx="6"
            cy="6"
            r="6"
            stroke-width="0"
            fill="green"
          />
          <circle
            v-if="tooltipData['Is expected?'] == 'No'"
            cx="6"
            cy="6"
            r="6"
            stroke-width="0"
            fill="red"
          />
        </svg>
        <h5 style="display: inline-block; margin: 10px">
          {{ (tooltipData.Confidence * 100).toFixed(2) }}%
        </h5>
      </div>
      <p style="font-size: 14px">
        Matched as
        <b>{{ tooltipData["Actual intent"] }}</b>
      </p>

      <el-tooltip :title="`Move this example to ${tooltipData['Actual intent']}`">
        <el-button type="plain" size="small" @click="moveExample"> Move </el-button>
      </el-tooltip>
    </div>
  </el-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Tooltip",
  props: {
    tooltipData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    moveExample() {
      console.log("Moving to", this.tooltipData["Actual Intent"]);
    },
  },
});
</script>
