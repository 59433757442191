<template>
  <el-card v-if="id && node" style="margin-bottom: 8px">
    <div slot="header" class="widget-header">
      <div style="display: flex; align-items: center">
        <el-input
          v-if="editNodeId"
          v-model.trim="newNodeId"
          @keyup.esc.native="editNodeId = false"
          @change="renameNode"
          style="width: 500px"
        />
        <h2
          v-else
          style="cursor: pointer; line-height: 36px; margin: 0"
          @click="
            newNodeId = id;
            editNodeId = true;
          "
        >
          {{ id }}
        </h2>
        <span style="margin-left: 10px">(trigger)</span>
      </div>
      <div>
        <el-tooltip effect="dark" content="Save node" placement="bottom">
          <el-button
            circle
            type="success"
            size="small"
            :plain="true"
            :disabled="!valid"
            :loading="saving"
            icon="el-icon-check"
            @click="saveChanges"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="Delete node" placement="bottom">
          <el-button
            circle
            size="small"
            type="danger"
            :loading="deleting"
            icon="el-icon-delete"
            @click="deleteNode"
          />
        </el-tooltip>
      </div>
    </div>

    <div v-if="identical" class="duplicate">
      <el-tooltip
        style="margin-right: 5px"
        :content="`This trigger has the same text pattern and same context`"
        placement="top"
      >
        <i class="el-icon-warning" /> </el-tooltip
      >Duplicated trigger :
      <b @click="select('trigger', identical)">{{ identical }}</b>
    </div>
    <!-- $store.state.activeNodeType !== 'trigger' -->
    <el-collapse value="basic">
      <el-collapse-item title="Basic Editor" name="basic">
        <el-form label-position="right" label-width="100px" :rules="rules">
          <el-form-item>
            <template slot="label">
              Conditions
              <el-tooltip
                content="This trigger will get activated if these conditions met"
                placement="top"
              >
                <i class="el-icon-question has-text-grey" />
              </el-tooltip>
            </template>

            <ConditionEditor :conditions="node.conditions" />
          </el-form-item>

          <el-form-item label="Trigger type">
            <el-radio-group v-model="node.type">
              <el-radio-button label="text">Text</el-radio-button>
              <el-radio-button label="entity">Entity</el-radio-button>
              <el-radio-button label="email">Email</el-radio-button>
              <el-radio-button label="name">Name</el-radio-button>
              <el-radio-button label="date">Date</el-radio-button>
              <el-radio-button label="number">Number</el-radio-button>
              <el-radio-button label="sentiment">Emotion</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="Media type">
            <el-radio-group v-model="node.type">
              <el-radio-button label="location">Location</el-radio-button>
              <el-radio-button label="image">Image</el-radio-button>
              <el-radio-button label="audio">Audio</el-radio-button>
              <el-radio-button label="video">Video</el-radio-button>
              <el-radio-button label="file">File</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <RegexEditor :node="node" v-if="node.type === 'text'"> </RegexEditor>

          <el-form-item v-if="node.type === 'entity'" prop="pattern">
            <template slot="label">
              Entity
              <el-tooltip content="Message contains entity" placement="top">
                <i class="el-icon-question has-text-grey" />
              </el-tooltip>
            </template>
            <ConditionEditor
              :conditions="node.entityConditions"
              :actionAddBtnText="'Entity'"
              :conditionPropertyOptions="[]"
            />
          </el-form-item>

          <el-form-item>
            <template slot="label">
              Priority
              <el-tooltip content="Higher number equals higher priority" placement="top">
                <i class="el-icon-question has-text-grey" />
              </el-tooltip>
            </template>
            <el-input
              v-model.number="node.priority"
              type="number"
              class="priority"
              style="min-width: 250px; width: 60%"
            />
          </el-form-item>

          <el-form-item>
            <template slot="label">
              Context
              <el-tooltip content="Last shown content name" placement="top">
                <i class="el-icon-question has-text-grey" />
              </el-tooltip>
            </template>
            <el-input
              v-model.trim="node.context"
              type="text"
              placeholder="Optional"
              style="min-width: 250px; width: 60%"
            />
          </el-form-item>

          <el-form-item label="Options">
            <el-checkbox v-model="node.entities.languageDetect">Detect Language</el-checkbox>
          </el-form-item>

          <el-row v-if="node.type === 'text'" :gutter="24">
            <el-col :span="12" :xs="24">
              <el-button icon="el-icon-plus" @click="addTest(true)">Add positive test</el-button>

              <div v-for="(test, index) in node.tests.positive" :key="index" :show-message="false">
                <el-input
                  v-model="test.text"
                  @change="testTrimAfterChange($event, node.tests.positive, index)"
                  style="margin-top: 8px; margin-right: 8px; width: 80%"
                />
                <i v-if="regexTest(test.text, true)" class="el-icon-check has-text-success" />
                <i v-else class="el-icon-close has-text-danger" />
              </div>
            </el-col>
            <el-col :span="12" :xs="24">
              <el-button icon="el-icon-plus" @click="addTest(false)">Add negative test</el-button>
              <div v-for="(test, index) in node.tests.negative" :key="index" :show-message="false">
                <el-input
                  v-model="test.text"
                  @change="testTrimAfterChange($event, node.tests.negative, index)"
                  style="margin-top: 8px; margin-right: 8px; width: 80%"
                />
                <i v-if="regexTest(test.text, false)" class="el-icon-check has-text-success" />
                <i v-else class="el-icon-close has-text-danger" />
              </div>
            </el-col>
          </el-row>

          <el-row v-if="node.type === 'location'" :gutter="24">
            <el-col>
              <h1>Setup Geo-fence</h1>
              Upload a shapefile or geojson file
              <el-upload
                class="upload-demo"
                drag
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  Drop file here or
                  <em>click to upload</em>
                </div>
                <div slot="tip" class="el-upload__tip">
                  shp/json files with a size less than 100kb
                </div>
              </el-upload>
              <br />Upload a GeoJSON
              <JSONEditor
                v-if="$store.state.showAdvanced"
                v-model="node.geojson"
                @valid="valid = true"
                @invalid="valid = false"
              />
            </el-col>
          </el-row>

          <el-row v-if="node.type === 'sentiment'" :gutter="24">
            <h1>Sentiment Score</h1>
            <el-radio-group v-model="node.sentiment">
              <el-radio-button :value="0.25">Angry</el-radio-button>
              <el-radio-button :value="0.5">Neutral</el-radio-button>
              <el-radio-button :value="0.75">Happy</el-radio-button>
            </el-radio-group>
          </el-row>

          <br />

          <el-row>
            <el-col :sm="20" :xs="24">
              <EventNodeSelector v-model="node" @save-side-effect="saveChanges" />
            </el-col>
          </el-row>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <JSONEditor
      v-if="$store.state.showAdvanced"
      v-model="node"
      @valid="valid = true"
      @invalid="valid = false"
    />
  </el-card>
  <div v-else style="width: 75%; margin: auto">
    <el-card>
      <div v-if="id">
        <p>
          Trigger
          <el-tag>{{ id }}</el-tag
          >does not exist.
        </p>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JSONEditor from "@/components/JSONEditor";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import ConditionEditor from "@/components/ConditionEditor";
import RegexEditor from "@/components/RegexEditor";
import {
  contentNodeConditionOptionsDataMixin,
  contentNodeConditionOptionsComputedMixin,
} from "@/mixins/contentNode";
import errorHandlerMixin from "@/mixins/errorHandler";

import {
  checkIdenticalTrigger,
  checkWildCardTrigger,
} from "@/helperMethods/task_management/trigger";

export default {
  mixins: [
    contentNodeConditionOptionsDataMixin,
    contentNodeConditionOptionsComputedMixin,
    errorHandlerMixin,
  ],
  components: {
    JSONEditor,
    EventNodeSelector,
    ConditionEditor,
    RegexEditor,
  },
  props: {
    id: {
      type: String,
      default() {
        return "<no name>";
      },
    },
    collapse: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      nodeType: "trigger",
      editNodeId: false,
      newNodeId: "<no name>",
      saving: false,
      deleting: false,
      valid: true,
      rules: {
        pattern: [
          {
            validator: (rule, value, cb) => {
              if (this.regex && this.wildCard) {
                cb();
              } else {
                cb(new Error("Invalid regular expression."));
              }
            },
          },
        ],
      },
      fileList: [],
    };
  },
  computed: {
    ...mapGetters(["triggerDiff"]),
    node: {
      get() {
        let node = this.$store.state.nodes.trigger[this.id];

        if (!node) {
          return null;
          // this.$set(this.$store.state.nodes.trigger, this.id, {
          //   type: "text",
          //   priority: 0,
          //   pattern: "/test/i",
          //   tests: {
          //     positive: [],
          //     negative: []
          //   },
          //   context: "",
          //   event: "",
          // });

          // node = this.$store.state.nodes.trigger[this.id];
        }

        if (node.type == null) {
          this.$set(this.$store.state.nodes.trigger[this.id], "type", "text");
        }

        if (node.tests == null) {
          this.$set(this.$store.state.nodes.trigger[this.id], "tests", {
            positive: [],
            negative: [],
          });
        }

        if (node.entities == null) {
          this.$set(this.$store.state.nodes.trigger[this.id], "entities", {
            languageDetect: true,
          });
        }

        if (!node.conditions) {
          const conditions = [];
          this.$set(this.$store.state.nodes.trigger[this.id], "conditions", conditions);
        }

        if (!node.entityConditions) {
          const entityConditions = [];
          this.$set(this.$store.state.nodes.trigger[this.id], "entityConditions", entityConditions);
        }

        return node;
      },
      set(value) {
        this.$store.state.nodes.trigger[this.id] = value;
        this.$forceUpdate();
      },
    },
    regex() {
      let flags = this.node.pattern.replace(/.*\/([gimy]*)$/, "$1") || "i";
      let pattern = this.node.pattern.replace(new RegExp("^/(.*?)/" + flags + "$"), "$1");
      try {
        let regex = new RegExp(pattern, flags);
        return regex;
      } catch (e) {
        return null;
      }
    },
    identical() {
      return checkIdenticalTrigger(this.id, this.triggerDiff);
    },
    wildCard() {
      return !checkWildCardTrigger(this.id);
    },
  },
  methods: {
    querySearch(condition) {
      return (queryString, cb) => {
        const { property } = condition;
        const entityProperty = property.split(".").slice(-1)[0];
        const { entity } = this;

        const keywords =
          property && entity[entityProperty] && entity[entityProperty].list
            ? Object.keys(entity[entityProperty].list)
            : [];
        const results = queryString ? keywords.filter(this.createFilter(queryString)) : keywords;
        // call callback function to return suggestions
        cb(results.map((item) => ({ value: item, link: item })));
      };
    },
    testTrimAfterChange(text, arr, index) {
      this.$set(arr, `${index}`, { text: text.trim() });
    },

    select(type, id) {
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
    createTriggerNode() {
      this.$store.dispatch("CREATE_TRIGGER_NODE", { id: this.tempNode.id });
      this.id = this.tempNode.id;
      this.tempNode.id = "";
    },
    handlePreview(data) {},
    handleRemove(data) {},

    regexTest(text, isPositive) {
      if (this.regex && this.regex.test(text)) {
        return isPositive;
      } else {
        return !isPositive;
      }
    },
    addTest(isPositive) {
      if (isPositive) {
        this.node.tests.positive.push({ text: "" });
      } else {
        this.node.tests.negative.push({ text: "" });
      }

      this.$forceUpdate();
    },
    deleteNode() {
      this.$confirm("This will delete the trigger. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      }).then(() => {
        this.deleting = true;
        return this.$store
          .dispatch("DELETE_NODE", { type: this.nodeType, id: this.id })
          .then(() => {
            this.$store.dispatch("UPDATE_TRIGGER_DIFF");
            this.deleting = false;

            this.$message({
              type: "success",
              message: "Deleted",
            });
          })
          .catch(() => {
            this.deleting = false;
            this.$message({
              type: "error",
              message: "Error deleting trigger from the server",
            });
          });
      });
    },
    renameNode() {
      let oldId = this.id;
      let newId = this.newNodeId;

      if (oldId !== newId) {
        this.$store.commit("RENAME_NODE", {
          type: this.nodeType,
          oldId,
          newId,
        });
        this.$emit("select", { type: this.nodeType, id: newId });
        this.$nextTick(() => {
          this.saveChanges();
          this.$store.dispatch("DELETE_RENAMED_OLD_NODE", {
            type: this.nodeType,
            id: oldId,
          });
        });
      }

      this.editNodeId = false;
    },
    saveChanges() {
      this.node.tests.positive = this.node.tests.positive.filter((t) => t.text.length > 0);
      this.node.tests.negative = this.node.tests.negative.filter((t) => t.text.length > 0);

      this.saving = true;
      this.editNodeId = false;
      this.$store
        .dispatch("EDIT_NODE", {
          type: this.nodeType,
          id: this.id,
          node: this.node,
        })
        .then(() => {
          this.$store.dispatch("UPDATE_TRIGGER_DIFF");
          this.saving = false;
          this.$message({
            type: "success",
            message: "Trigger Saved",
          });
        })
        .catch((err) => {
          this.saving = false;
          const errMessage = this.graphQLError(err, `Saving Failed`);
          this.$message({
            type: "error",
            message: errMessage,
          });
        });
    },
    getEventOptions() {
      let options = {};
      this.$store.state.nodes.event.forEach(function (el) {
        options[el] = el;
      });
      return options;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/scss/colors.scss";

.duplicate {
  margin: 0 0 20px 0;
  color: $color-danger;
}
.duplicate b {
  cursor: pointer;
}
</style>
