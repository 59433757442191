<template>
  <el-card style="margin-bottom: 20px" :body-style="{ padding: '0px' }">
    <template v-if="isImage">
      <a
        v-loading="isBusy"
        :href="file.url"
        target="_blank"
        class="file-preview"
        :style="{ backgroundImage: `url('${file.url}')` }"
      ></a>
    </template>
    <template v-else>
      <a v-loading="isBusy" :href="file.url" target="_blank" class="file-preview">
        <span class="file-extension">
          {{ file.mimetype }}
        </span>
      </a>
    </template>
    <div style="padding: 14px">
      <small
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block"
        >{{ file.name }}</small
      >
      <div class="bottom" style="text-align: right; margin-top: 10px">
        <el-button
          size="mini"
          icon="el-icon-share"
          circle
          style="margin-left: 0"
          type="text"
          class="has-text-dark"
          @click="generateToken"
        ></el-button>
        <el-button
          v-copy="{ text: file.url }"
          size="mini"
          class="has-text-dark"
          icon="el-icon-document-copy"
          circle
          style="margin-left: 0"
          type="text"
        ></el-button>
        <el-button
          @click="onDownload"
          size="mini"
          icon="el-icon-download has-text-info"
          style="margin-left: 0"
          circle
          type="text"
        ></el-button>
        <el-button
          @click="onDelete"
          size="mini"
          icon="el-icon-delete"
          class="has-text-danger"
          style="margin-left: 0"
          circle
          type="text"
        ></el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { copyText } from "@/helpers/copyText";

export default {
  props: ["file"],
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters(["storageType", "isAzureStorage", "isCephStorage"]),

    /**
     * Is image file
     *
     * @return {boolean}
     */
    isImage() {
      const imageTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
      return _.includes(imageTypes, this.file.mimetype);
    },
  },
  methods: {
    /**
     * On delete file
     *
     * @return {void}
     */
    async onDelete() {
      if (this.isBusy) {
        return;
      }
      try {
        const shouldDelete = await this.$confirm(
          "This will permanently delete the file. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        this.delete();
      } catch (error) {
        console.log("delete cancel");
      }
    },

    async delete() {
      if (this.isBusy) {
        return;
      }
      try {
        this.isBusy = true;
        let form = new FormData();
        if (this.isAzureStorage) {
          form.append("filename", this.file.filepath);
          const response = await this.$rest("post", "deleteFile", form);
          if (!response.isSuccessful) {
            throw new Error("Error occurred while trying to delete!");
          }
        } else if (this.isCephStorage) {
          const fileName = this.file.name;
          const folderName = this.file.folder;
          form.append("fileName", fileName);
          if (folderName) {
            form.append("folderName", folderName);
          }
          const response = await this.$rest("post", "miniapp_ceph_delete_file", form);
          if (!response.isDeleted) {
            throw new Error("Error occurred while trying to delete!");
          }
        }
      } catch (error) {
        this.$notify({
          message: "Error occurred while trying to delete!",
          type: "error",
        });
      } finally {
        this.isBusy = false;
        this.$emit("after-delete");
      }
    },

    async generateToken() {
      if (this.longExpiredUrl) {
        this.$notify({
          message: "Long sas URL for this file was generated and copied to your clipboard",
          type: "success",
        });
        copyText(this.longExpiredUrl);
        return;
      }
      try {
        let form = new FormData();
        if (this.isAzureStorage) {
          form.append("filename", this.file.filepath);
          form.append("folderName", this.file.folderName);
          const response = await this.$rest("post", "generate_long_sas_url", form);
          if (!response.isSuccessful) {
            throw new Error("Error occurred while trying to generate sasURL!");
          }
          this.$notify({
            message: "Long sas URL for this file was generated and copied to your clipboard",
            type: "success",
          });
          this.longExpiredUrl = response.url;
          copyText(response.url);
        } else if (this.isCephStorage) {
          this.$notify({
            message: "Currently we dont support generate long sasURL for Ceph Storage",
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          message: "Error occurred while trying to generate sasUrl!",
          type: "error",
        });
      }
    },

    /**
     * On download file
     *
     * @return {void}
     */
    async onDownload() {
      if (this.isBusy) {
        return;
      }
      this.isBusy = true;
      try {
        const form = new FormData();
        form.append("filepath", this.file.filepath);

        let endpoint;
        if (this.isAzureStorage) {
          endpoint = "downloadFile";
        } else if (this.isCephStorage) {
          endpoint = "miniapp_ceph_download_file";
        } else {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to download file, undefined file storage provider.`,
          });
          return;
        }

        const response = await this.$rest("post", endpoint, form);
        const link = document.createElement("a");

        link.download = this.file.name;
        link.href = `data:${this.file.mimetype};base64,` + response;
        link.click();
        link.remove();
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: `Failed to download file, please try again later.`,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors.scss";

.file-preview {
  display: block;
  height: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: $color-light;
}

.file-extension {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: $color-lightgrey;
  width: 100%;
  text-align: center;
  word-break: break-all;
  font-size: 20px;
}

>>> i {
  font-weight: bold;
}
</style>
