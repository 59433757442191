<template>
  <el-form :model="user" :rules="rules" ref="visitorForm">
    <el-form-item
      v-for="(meta, key) in viewMeta.fields"
      style="margin-bottom: 0"
      :label="meta.displayValue"
      :key="key"
    >
      <el-switch
        v-if="meta.dataType === 'boolean'"
        v-model="user[key]"
        size="mini"
        :disabled="!meta.editable"
      ></el-switch>
      <el-input-number
        v-else-if="meta.dataType === 'number'"
        v-model="user[key]"
        size="mini"
        :disabled="!meta.editable"
        :min="meta.min"
        :max="meta.max"
      ></el-input-number>
      <el-select
        v-else-if="meta.dataType === 'list' && meta.editable === true && meta.multiSelect === true"
        v-model="user[key]"
        multiple
        filterable
        size="mini"
        :collapse-tags="true"
        style="width: 100%"
        :clearable="meta.closable"
        :placeholder="meta.displayValue"
      >
        <el-option v-for="tag in meta.listValues" :key="tag" :label="tag" :value="tag" />
      </el-select>
      <el-select
        v-else-if="meta.dataType === 'list' && meta.editable === true"
        v-model="user[key]"
        :collapse-tags="true"
        filterable
        size="mini"
        style="width: 100%"
        :clearable="meta.closable"
        :placeholder="meta.displayValue"
      >
        <el-option v-for="tag in meta.listValues" :key="tag" :label="tag" :value="tag" />
      </el-select>
      <el-input
        v-else
        v-model="user[key]"
        :disabled="!meta.editable"
        clearable
        size="mini"
        :type="meta.multiLine === true ? 'textarea' : 'text'"
      ></el-input>
    </el-form-item>

    <el-button @click="saveUserInfo" size="mini" type="primary">Save</el-button>
    <el-button
      icon="el-icon-view"
      @click="launchChatAsUser"
      size="mini"
      type="primary"
      v-if="checkRoles(getPermittedRoles('clone_user'))"
    >
      Launch chat as user
    </el-button>
  </el-form>
</template>

<script>
import _ from "lodash";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

export default {
  props: ["user", "viewMeta"],
  data() {
    return {
      rules: {},
    };
  },
  beforeMount() {
    this.prepareFormFields();
  },
  computed: {
    previewURL() {
      const appConfig = window.appConfig;
      const currentUrl = window.location.href;

      if (currentUrl.includes("localhost")) {
        return "http://localhost:8081/?isPreview=true";
      } else if (appConfig.WEBCHAT_URL) {
        return `${appConfig.WEBCHAT_URL}?isPreview=true`;
      } else {
        return `${appConfig.WEBCHAT_URL}?isPreview=true`;
      }
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    prepareFormFields() {
      for (const field in this.viewMeta.fields) {
        const shouldStringify = typeof this.user[field] === "object";
        if (this.viewMeta.fields[field].dataType === "string" && shouldStringify) {
          this.user[field] = JSON.stringify(this.user[field]);
        }
      }
    },
    launchChatAsUser() {
      const userId = this.user.RowKey;
      const pageId = this.user.PartitionKey;
      const newParams = new URLSearchParams();
      const currentParams = new URLSearchParams(location.search);
      const server = currentParams.get("server");
      const cloud = currentParams.get("cloud");
      let serverQuery = "";
      // this is for dashboard and webchat local debugging using remote server
      if (server) {
        if (cloud === "keyreply") {
          serverQuery = `&server=${server}&cloud=keyreply`;
        } else {
          serverQuery = `&server=${server}`;
        }
      }
      if (this.user.appSource && this.user.appSource !== "N/A") {
        newParams.set("user-source", this.user.appSource);
      }
      if (this.user.role && this.user.role !== "N/A") {
        newParams.set("user-role", this.user.role);
      }
      const newParamString = newParams.toString();
      const params = newParamString && "&" + newParamString;

      this.$store
        .dispatch("ENCRYPT_USER_INFO", {
          userId: userId,
          pageId: pageId,
        })
        .then((jwtToken) => {
          if (jwtToken) {
            window.open(this.previewURL + serverQuery + params + "&token=" + jwtToken, "_blank");
          } else {
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: `Error encrypt user info`,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error encrypt user info`,
          });
        });
    },
    saveUserInfo() {
      this.$refs["visitorForm"].validate((valid) => {
        if (valid) {
          const userId = this.user.RowKey;
          const pageId = this.user.PartitionKey;
          const stateData = {};
          _.forEach(this.viewMeta.fields, (field, key) => {
            if (field.editable === true) {
              stateData[key] = this.user[key];
            }
          });
          this.$store
            .dispatch("SAVE_USER_INFO", {
              userId: userId,
              pageId: pageId,
              statePayload: JSON.stringify(stateData),
            })
            .then((response) => {
              //this.loading = false;
              this.$message({
                type: "success",
                message: "User info updated.",
              });
            })
            .catch((err) => {
              // this.loading = false;
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: `Error updating user info.`,
              });
            });
        } else {
          return false;
        }
      });
    },
    generateValidationRules() {
      _.forEach(this.viewMeta.fields, (field, key) => {
        let prompt = "input";
        if (field.dataType === "list") {
          prompt = "select";
        }
        if (field.required === true) {
          const rule = {
            required: true,
            message: `Please ${prompt} ${field.displayValue}`,
            trigger: "blur",
          };
          this.rules[key] = [rule];
        }
      });
    },
  },
  mounted() {
    this.generateValidationRules();
  },
};
</script>
