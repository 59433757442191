export const EVENT_NODE_TYPES = [
  {
    label: "Jump to Content",
    name: "goto",
  },
  {
    label: "Capture Data",
    name: "capture",
  },
  {
    label: "Trigger API request",
    name: "callApi",
  },
  {
    label: "Fill Form",
    name: "form_processor",
  },
  {
    label: "Start Flow",
    name: "startflow",
  },
  {
    label: "Set Variables",
    name: "set",
  },
  {
    label: "Escalate to Live Agent",
    name: "escalate",
  },
  {
    label: "Embed Content",
    name: "$display",
  },
  {
    label: "Custom Event",
    name: "custom event",
  },
  {
    label: "Redirect Bot",
    name: "redirect_bot",
  },
];
