<template>
  <div class="trending-topics">
    <EditIntent
      v-if="openEdit"
      :handleEditModel="handleOpenModel"
      :listTrending="data"
      :intentEdit="intentEdit"
    />
    <div class="trending-topics-header">
      <div class="trending-topics-header-title"><h3>Trending Items</h3></div>
      <div class="trending-topics-header-filter">
        <div class="block">
          <span class="demonstration"><b>Filter by date range</b></span>
          <el-date-picker
            v-model="filterDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="trending-topics-table" :style="trendingStyleHeight()">
      <p v-if="trendingData.length === 0">
        There is no trending topic in this range. Try some other dates
      </p>

      <table v-else>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th><p style="margin: 0; color: gray">Trending score</p></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="trending in trendingData"
            :key="`trending-${trending.id}`"
            v-dragging="{
              item: trending,
              list: trendingData,
              group: 'trendingData',
            }"
          >
            <td>
              <div class="icon-style" style="width: 40px; height: 60px">
                <i
                  class="el-icon-s-unfold"
                  style="font-size: 25px"
                  v-if="checkAddedId(trending.id)"
                >
                </i>
              </div>
            </td>
            <td>
              <div class="icon-style" v-if="!checkCurrentlyIntent(trending.status)">
                <el-checkbox
                  :checked="checkAddedId(trending.id)"
                  @change="selectIntent(trending)"
                  :disabled="setDisableCheckBox(trending.id)"
                  v-if="!checkEmptyExample(trending.examples, trending.intentId)"
                ></el-checkbox>
                <i class="el-icon-warning icon-orange" style="font-size: 25px" v-else></i>
              </div>
            </td>
            <td>
              <div
                style="text-align: start; margin-left: 5px"
                v-if="getIntentName(trending.intentId).intentName"
              >
                <div>
                  <p style="margin: 0; font-weight: bold; word-break: break-word">
                    {{ getIntentName(trending.intentId).intentName }}
                    <el-tag style="margin-left: 5px" type="success">Intent</el-tag>
                  </p>
                  <p style="margin: 0; font-size: 12px">
                    {{ getTextFromIntentId(trending.intentId) }}
                    <el-tag style="margin-left: 5px" type="success" v-if="trending.createdByAdmin"
                      >Admin</el-tag
                    >
                  </p>
                </div>
              </div>
              <div style="text-align: start; margin-left: 5px" v-else>
                <div v-if="existPublishedExample(trending.examples)">
                  <p style="margin: 0; font-weight: bold; word-break: break-word">
                    {{ existPublishedExample(trending.examples) }}
                    <el-tag style="margin-left: 5px" type="success">Content Node</el-tag>
                  </p>
                  <p style="margin: 0; font-size: 12px">
                    {{ getTextFromIntentId(trending.intentId) }}
                    <el-tag style="margin-left: 5px" type="success" v-if="trending.createdByAdmin"
                      >Admin</el-tag
                    >
                  </p>
                </div>
                <div v-else>
                  <b
                    >{{ getTextFromIntentId(trending.intentId) }}
                    <el-tag style="margin-left: 5px" type="success">Content Node</el-tag>
                  </b>
                  <el-tag style="margin-left: 5px" type="success" v-if="trending.createdByAdmin"
                    >Admin</el-tag
                  >
                </div>
              </div>
            </td>
            <td>
              <i
                class="el-icon-edit"
                @click="handleEditModel(trending)"
                style="font-size: 25px"
              ></i>
            </td>
            <td>
              <p style="text-align: center; margin: 0">{{ trending.count || "ADMIN" }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EditIntent from "./EditIntent/Index.vue";
import {
  getPublishedExample,
  getTextButton,
  getHeightElement,
  checkCanPublishTopic,
  getIntentName,
} from "@/helperMethods/trendingTopics/methods";
import { trendingFilterShortcuts } from "@/helperMethods/trendingTopics/util";
import { TRENDING_TOPIC_STATUS } from "@/helperMethods/trendingTopics/type";
import _ from "lodash";
export default {
  components: { EditIntent },
  props: ["trendingData", "selectIntent", "listSubmit", "limit", "setLoading"],
  data() {
    const defaultEnd = new Date();
    const defaulStart = new Date();
    defaulStart.setTime(defaulStart.getTime() - 3600 * 1000 * 24 * 7);
    return {
      data: [],
      openEdit: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: trendingFilterShortcuts,
      },
      filterDate: [defaulStart, defaultEnd],
      heightTrending: 0,
      intentEdit: {},
    };
  },
  updated() {
    this.heightTrending = getHeightElement("trending-topics", "trending-topics-header", 30);
  },
  methods: {
    getIntentName(intentId) {
      return getIntentName(intentId);
    },
    trendingStyleHeight() {
      return {
        // height: `${this.heightTrending}px`,
        height: `calc(100% - 100px)`,
      };
    },
    handleEditModel(data) {
      this.data = this.trendingData;
      this.intentEdit = data;
      this.handleOpenModel();
    },
    handleOpenModel() {
      this.openEdit = !this.openEdit;
    },
    checkAddedId(id) {
      return this.listSubmit.includes(id);
    },
    checkCurrentlyIntent(status) {
      return status === TRENDING_TOPIC_STATUS.CURRENTLY;
    },
    checkEmptyExample(examples, intentId) {
      return checkCanPublishTopic(intentId, examples);
    },
    existPublishedExample(examples = "") {
      if (!examples) return "";
      return getPublishedExample(examples);
    },
    setDisableCheckBox(id) {
      return this.limit == 6 && !this.listSubmit.includes(id);
    },
    checkLengthTrendingTopics() {
      return this.trendingData.length >= 0;
    },
    getTextFromIntentId(data) {
      return getTextButton(data);
    },
  },
  watch: {
    filterDate: {
      handler: async function () {
        this.setLoading();
        const [startDate, endDate] = this.filterDate;
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        this.$store.commit("SET_FILTER_DATE", [startDate, endDate]);
        await this.$store.dispatch("TRENDING_TOPICS_FILTERD_BY_RANGE", { startDate, endDate });
        this.setLoading();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";
.demonstration {
  margin-right: 20px;
}

.el-table table tbody tr {
  > td:last-child {
    text-align: center;
  }
}
.trending-topics {
  box-shadow: 3px -3px 3px 2px rgba(0, 0, 0, 0.17);
  height: 100%;
  h3 {
    color: $color-grey;
  }
  &-header {
    padding: 10px;
    border-bottom: 3px solid black;
    height: 100px;
    &-filter {
      padding: 10px 0;
    }
  }
  &-table {
    overflow-y: scroll;
    padding: 0 30px;
    height: 100%;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    table {
      width: 100%;
      color: $color-dark;
      border-spacing: 0;
      padding: 0px 5px;
    }
    table th {
      padding: 6px 0;
    }
    table thead tr {
      height: 45px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: #757a834f;
      }
    }
    table tbody tr {
      height: 45px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: #757a834f;
      }
      &:hover {
        background: #676b721f;
        cursor: pointer;
      }
    }
    .icon-orange {
      color: $color-warning;
    }
    .icon-style {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
i {
  cursor: pointer;
}
</style>
