<template>
  <el-card style="box-shadow: none" shadow="never">
    <div slot="header">
      <el-row
        type="flex"
        style="margin-bottom: 0; align-items: center; justify-content: space-between"
      >
        <span>Livechat</span>
        <el-switch v-model="value.inactivityManager.livechat.enabled"></el-switch>
      </el-row>
    </div>

    <div>
      <div>
        <small style="font-style: italic" class="has-text-lightgrey">
          Prompts user for a reply when user's inactive time exceeds inactivity threshold during a
          livechat.
        </small>
      </div>
      <br />
      <div ref="livechat-inactivity" v-if="value.inactivityManager.livechat.enabled">
        <el-input
          placeholder="Please input"
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.livechat.threshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <el-input
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.livechat.contentNode"
        >
          <template slot="prepend">
            Content Node
            <el-tooltip
              effect="dark"
              content="Content node show to user when inactivity threshold met."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <el-divider>Second Prompt</el-divider>

        <div>
          <small style="font-style: italic" class="has-text-lightgrey">
            Prompts user for a reply again when user's inactive time exceeds inactivity threshold
            during a livechat for the second time.
          </small>
        </div>
        <br />

        <el-checkbox
          v-model="value.inactivityManager.livechat.enabledSecondPrompt"
          label="Enable"
          border
          style="margin-bottom: 15px"
        ></el-checkbox>

        <el-input
          v-if="value.inactivityManager.livechat.enabledSecondPrompt"
          placeholder="Please input"
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.livechat.thresholdSecondPrompt"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <div
          ref="livechat-inactivity-second-prompt"
          v-if="value.inactivityManager.livechat.enabledSecondPrompt"
        >
          <el-input
            style="margin-bottom: 15px"
            v-model="value.inactivityManager.livechat.contentNodeSecondPrompt"
          >
            <template slot="prepend">
              Content Node
              <el-tooltip
                effect="dark"
                content="Content node show to user when second inactivity threshold met."
                placement="right"
              >
                <i class="el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
            </template>
          </el-input>
        </div>

        <el-divider>Auto Resolve</el-divider>

        <el-checkbox
          v-if="!value.inactivityManager.livechat.enabledSecondPrompt"
          v-model="value.inactivityManager.livechat.autoResolve"
          label="Auto Resolve After First Prompt"
          border
          style="margin-bottom: 15px"
        ></el-checkbox>
        <el-input
          v-if="!value.inactivityManager.livechat.enabledSecondPrompt"
          placeholder="Please input"
          style="margin-bottom: 15px"
          type="number"
          v-model="value.inactivityManager.livechat.autoResolveWait"
        >
          <template slot="prepend">
            Wait Time
            <el-tooltip
              effect="dark"
              content="Time in seconds (s) to resolve chat. If set to 0, it will auto resolve immediately after content node sent"
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>
        <el-checkbox
          v-if="value.inactivityManager.livechat.enabledSecondPrompt"
          v-model="value.inactivityManager.livechat.autoResolveSecondPrompt"
          label="Auto Resolve After Second Prompt"
          border
          style="margin-bottom: 15px"
        ></el-checkbox>
        <el-input
          v-if="value.inactivityManager.livechat.enabledSecondPrompt"
          placeholder="Please input"
          style="margin-bottom: 15px"
          type="number"
          v-model="value.inactivityManager.livechat.autoResolveWaitSecondPrompt"
        >
          <template slot="prepend">
            Wait Time
            <el-tooltip
              effect="dark"
              content="Time in seconds (s) to resolve chat. If set to 0, it will auto resolve immediately after content node sent"
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>
      </div>

      <el-divider>Prompt Agent Inactive</el-divider>

      <el-checkbox
        v-model="value.inactivityManager.livechatAgent.enabled"
        label="Enable"
        border
        style="margin-bottom: 15px"
      ></el-checkbox>

      <div ref="livechat-inactivity-agent" v-if="value.inactivityManager.livechatAgent.enabled">
        <el-input
          placeholder="Please input"
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.livechatAgent.threshold"
        >
          <template slot="prepend">
            Threshold
            <el-tooltip
              effect="dark"
              content="The number of seconds before the bot prompts for a reply on agent inactivity."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>

        <el-input
          style="margin-bottom: 15px"
          v-model="value.inactivityManager.livechatAgent.contentNode"
        >
          <template slot="prepend">
            Content Node
            <el-tooltip
              effect="dark"
              content="Content node show to user when inactivity threshold met on agent inactivity."
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em" />
            </el-tooltip>
          </template>
        </el-input>
      </div>

      <!-- <el-checkbox
          v-model="value.inactivityManager.livechat.ignoreRatingContent"
          label="Ignore Rating Content"
          border
          style="margin-bottom: 15px;"
      ></el-checkbox>-->
    </div>
  </el-card>
</template>

<script>
export default {
  name: "InactivityManagerLivechat",
  props: ["value"],
  watch: {
    value: {
      handler(_newValue) {
        const newValue = _.cloneDeep(_newValue);
        if (newValue.inactivityManager.livechat.enabledSecondPrompt) {
          this.value.inactivityManager.livechat.autoResolve = false;
          this.value.inactivityManager.livechat.autoResolveWait = 0;
        } else {
          this.value.inactivityManager.livechat.autoResolveSecondPrompt = false;
          this.value.inactivityManager.livechat.autoResolveWaitSecondPrompt = 0;
        }
      },
      deep: true,
    },
  },
};
</script>
