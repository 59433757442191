<template>
  <div class="row-fluid">
    <el-popover
      v-dragging="{ item: reply, list: value, group: 'quick_replies' }"
      v-for="(reply, index) in value"
      placement="bottom"
      width="400"
      style="display: inline-block; border-radius: 15px"
      trigger="click"
      :key="index"
      :disabled="disabled"
    >
      <el-input ref="replies_input" v-model="reply.text" class="form-control">
        <template slot="prepend">Text</template>
      </el-input>
      <el-tabs>
        <el-tab-pane label="Event">
          <el-form
            :ref="`form${index}`"
            :model="value[index]"
            style="overflow-y: auto; max-height: 500px"
          >
            <EventNodeSelector
              v-model="value[index]"
              @save-side-effect="$emit('save-side-effect')"
            />
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <SynonymTable
        v-if="reply.synonym"
        :value="reply"
        badgeStyle="position:absolute;right:112px;top:22px;"
      />

      <!-- BUTTONS -->
      <el-button
        type="success"
        size="small"
        icon="el-icon-plus"
        @click="addSynonym(reply, index)"
        style="position: absolute; right: 64px; top: 16px"
      />
      <el-button
        type="danger"
        style="position: absolute; right: 16px; top: 16px"
        size="small"
        icon="el-icon-delete"
        @click="value.splice(index, 1)"
      />

      <div slot="reference" style="display: inline-block">
        <div
          class="quick-reply"
          style="cursor: pointer"
          :style="{
            borderBolor: !reply.event && !reply.data && !reply.phone ? '#E4392B' : '$color-info',
          }"
        >
          <img
            v-if="reply.image"
            style="margin-bottom: 4px"
            height="16px"
            width="16px"
            :src="reply.image"
          />

          <span>
            <el-tooltip
              class="item"
              effect="dark"
              content="This quick reply contains synonym"
              placement="top-start"
            >
              <i class="el-icon-share" v-if="reply.synonym"></i>
            </el-tooltip>

            {{ reply.text || "Quick Reply" }}
          </span>
          <!-- {{ reply.text || "Quick Reply" }} -->
          <i
            v-if="isInvalid(reply, index)"
            class="el-icon-warning quick-reply-icon has-text-danger"
            style="font-size: 1.4em"
          />
        </div>
      </div>
    </el-popover>

    <div
      v-if="value.length < 10 && !disabled"
      class="quick-reply"
      style="border-style: dashed; background: white; width: auto; cursor: pointer"
      @click="$emit('addQuickReply')"
    >
      <i class="quick-reply-icon el-icon-plus"></i> Quick reply
    </div>
  </div>
</template>
<script>
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import SynonymTable from "./Buttons/Synonym";
import Validations from "./Buttons/Validations";

export default {
  name: "Quickreplies",
  mixins: [Validations],
  components: {
    SynonymTable,
    EventNodeSelector,
  },
  props: {
    value: {
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      edit_replies: [],
    };
  },
  watch: {
    value() {
      this.setEditReplies();
    },
  },
  mounted() {
    this.setEditReplies();
  },
  methods: {
    /**
     * @description add in synonym variable if doesnt exists or append if variable exists
     * @param {button} button | quickreply
     * @param {buttonIndex} number
     * @return {void}
     */
    addSynonym(button, buttonIndex) {
      if (!button) {
        return;
      }

      const synonymExists = !!button.synonym;
      if (synonymExists) {
        button.synonym.push(`synonym`);
      } else {
        this.$set(button, "synonym", ["synonym1"]);
      }
      this.$set(this.value, buttonIndex, button);
    },
    editreply(index) {
      this["edit_replies"][index] = true;
      this.$forceUpdate();
    },
    setEditReplies() {
      if (this.value) {
        let i = 0;
        for (; i < this.value.length; i++) {
          this.edit_replies[i] = this.edit_replies[i] || false;
        }
      }
    },
    quitEditReply(index) {
      this.edit_replies[index] = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/colors.scss";

.quick-reply {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: $color-info;
  color: $color-info;
  padding: 5px 10px 5px 30px;
  border-radius: 15px;
  margin: 10px 8px;
  position: relative;
}
.quick-reply-icon {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
</style>
