<template>
  <div class="chat-list-wrapper">
    <el-row v-loading="fetchQueueChatLoading">
      <el-row
        class="chat-list-item"
        v-for="(chat, index) in leadPriorityChatsList"
        :key="`${chat.PartitionKey}-${chat.RowKey}`"
        @click.native="selectChat(chat.RowKey)"
      >
        <el-badge
          :class="getChatClass(chat.RowKey, chat, selectedChatId)"
          :value="chat.unreadMessage"
          :hidden="chat.unreadMessage === 0"
        >
          <el-row style="margin-bottom: 0; width: 100%">
            <el-col style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              <el-row type="flex" style="margin-bottom: 0; align-items: center">
                <ChatAvatar :chat="chat" style="display: inline-block" />
                <el-row
                  type="flex"
                  style="flex-direction: column; margin-bottom: 0; overflow: hidden"
                >
                  <span class="chat-name">{{ getChatName(chat) }}</span>
                  <div class="chat-item-meta-second-row">
                    {{ waitedTime[index] }}
                  </div>
                </el-row>
              </el-row>
            </el-col>
            <el-col
              v-if="Array.isArray(chat.agents) && chat.agents.length > 0"
              :span="24"
              class="has-text-dark"
              style="font-size: 0.6em; margin-top: 5px; white-space: normal; font-weight: 400"
            >
              <i>Attended by {{ chat.agents.join(", ") }}</i>
            </el-col>
          </el-row>
        </el-badge>
        <!-- </el-row> -->
      </el-row>

      <!-- Is Empty -->
      <p v-if="!chatsList || chatsList.length === 0">
        {{
          isUnassignedChatList
            ? "There are no users who need your help at the moment."
            : "You are not attending to any users at the moment."
        }}
      </p>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { simplifyMessage } from "@/helperMethods/livechat/simplifyMessage";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import { mapGetters } from "vuex";
import { getChatName } from "@/filters";
import {
  getAvatar,
  getChatClass,
  getDisplayId,
  getPreview,
  printDuration,
} from "@/helperMethods/livechat/util";

export default {
  name: "ChatsList",
  components: {
    ChatAvatar,
  },
  props: {
    chatsList: Array,
    selectedIndex: Number,
    closed: Boolean,
    name: String,
  },
  data() {
    return {
      waitedTime: [],
      tab: "queue",
    };
  },
  computed: {
    ...mapGetters(["fetchQueueChatLoading", "selectedChatId", "selectedChat", "queueChatsArray"]),
    isUnassignedChatList() {
      return this.name === "unassignedChatList";
    },
    isUnassignedChatListEmpty() {
      return this.chatsList.length === 0;
    },
    leadPriorityChatsList() {
      if (this.chatsList) {
        const sortedList = _.sortBy(this.chatsList, (chat) => {
          return moment(chat.startQueueTime).valueOf();
        });
        return sortedList;
      } else {
        return [];
      }
    },
  },

  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    printDuration,
    getChatName,
    selectChat(chatId) {
      this.$store.commit("SET_SELECTED_CHAT_ID", chatId);
      this.$store.dispatch("RESET_UNREAD_MESSAGE", chatId);
      this.$emit("update:closed", true);
    },
    getSessionHistory(chat) {
      if (chat.interactions) {
        const chatInteractions = _.orderBy(
          chat.interactions,
          [
            (interaction) => {
              return moment(interaction.date_created).valueOf();
            },
          ],
          ["asc"]
        );
        const afterSlice = _.slice(chatInteractions, chatInteractions.length - 15);
        const sessionHistory = _.chain(afterSlice).map(simplifyMessage).compact().value();
        return sessionHistory;
      }
      return [];
    },
    calculateWaitedTime() {
      this.waitedTime = _.map(this.leadPriorityChatsList, (chat) => {
        return this.printDuration(chat.startQueueTime, moment().format());
      });
    },
  },
  mounted() {
    this.calculateWaitedTimeinterval = setInterval(() => {
      this.calculateWaitedTime();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.calculateWaitedTimeinterval);
  },
};
</script>

<style scoped lang="scss">
@import "./styles/chat-list.scss";
</style>
