<template>
  <div>
    <el-input v-model="value[name]" style="width: 80%; max-width: 680px; margin-bottom: 1em">
      <div slot="prepend" style="width: auto; min-width: 180px">
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </div>
    </el-input>
    <el-color-picker v-model="value[name]" style="position: relative; vertical-align: top" />
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
