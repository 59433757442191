// polyfill all `core-js` features, including early-stage proposals:
import "core-js";
import { Vue, worker } from "./bootstrap";
import Root from "@/Root.vue";
import router from "@/router";
import store from "@/store";

Vue.directive("focus", {
  inserted: function (el) {
    el.querySelector("input")?.focus(); // Focus on mounted el-input
  },
});

function main() {
  const vm = new Vue({
    router,
    store,
    render: (h) => h(Root as any),
  }).$mount("#app");

  const $keyreply = (window as any).$keyreply;
  if ($keyreply) {
    $keyreply.getVM = () => vm;
  }
  return vm;
}

// Handle incoming messages as commits!
worker.onmessage = ({ data }) => {
  const { type, payload } = data;
  if (type) {
    store.commit(type, payload);
  }
};

main();
