<template>
  <div>
    <el-card>
      <el-row>
        <slot name="title"></slot>
      </el-row>
      <el-row>
        <el-card>
          <el-row>
            <b>General</b>
          </el-row>
          <el-row>
            <el-row>
              <span style="color: rgba(0, 0, 0, 0.55)"
                >Enter general information about the message template</span
              >
            </el-row>
            <el-form
              ref="messageTemplateForm"
              :label-position="'left'"
              label-width="160px"
              style="width: 100%"
            >
              <el-form-item label="Category">
                <el-radio-group
                  v-model="templateCategory"
                  :disabled="readOnly || addingNewLanguage"
                >
                  <el-radio
                    v-for="category in categories"
                    :key="category.value"
                    :label="category.value"
                    border
                  >
                    {{ category.label }}
                    <el-tooltip placement="bottom" :content="category.details">
                      <i class="el-icon-info" style="margin-left: 10px; color: grey" />
                    </el-tooltip>
                  </el-radio>
                  <el-radio v-if="otherTemplateCategory" label="OTHER" border>
                    Other
                    <span>({{ template_category }})</span>
                    <el-tooltip
                      placement="bottom"
                      content="The category that was before graph API v14. If you add other language for this template, this category already deprecated."
                    >
                      <i class="el-icon-info" style="margin-left: 10px; color: grey" />
                    </el-tooltip>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Name" show-word-limit>
                <el-input
                  :maxlength="maxLength.template_name"
                  show-word-limit
                  v-model="templateName"
                  :disabled="readOnly || addingNewLanguage"
                />
              </el-form-item>
              <el-form-item label="Language">
                <el-select
                  filterable
                  style="width: 100%"
                  v-model="template_language"
                  placeholder="Select"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="language in filteredLanguages"
                    :key="language.value"
                    :label="language.label"
                    :value="language.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-if="template_status" label="Status">
                <el-button :disabled="true" :type="statusIndicator" plain>{{
                  template_status
                }}</el-button>
              </el-form-item>
            </el-form>
          </el-row>
        </el-card>
      </el-row>
      <el-row>
        <el-card>
          <el-row>
            <b>Header</b>
            <span
              style="
                background-color: lightgray;
                border-radius: 5px;
                padding: 2px;
                font-size: small;
                margin-left: 8px;
              "
              >Optional</span
            >
          </el-row>
          <el-row>
            <el-row>
              <span style="color: rgba(0, 0, 0, 0.55)"
                >Add a title or choose which type of media you'll use for this header.</span
              >
            </el-row>
            <el-row>
              <el-row style="display: flex">
                <el-select
                  filterable
                  v-model="template_header.type"
                  placeholder="Select"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="type in headerTypes"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value"
                  />
                </el-select>
                <div
                  v-if="template_header.type === 'TEXT'"
                  style="margin-left: 20px; display: flex; flex-direction: column; flex-grow: 1"
                >
                  <el-input
                    placeholder="Enter header text"
                    v-model="template_header.text"
                    :maxlength="maxLength.template_header"
                    show-word-limit
                    :disabled="readOnly"
                  />
                  <div style="display: flex; align-items: center; margin-top: 5px">
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      @click="addVariable(template_header, 'text')"
                      v-if="!readOnly"
                      >Add Variable</el-button
                    >
                    <el-tooltip
                      style="color: gray; margin-left: 5px"
                      content="This is text you specify in the API that will be personalized to the customer, such as their name or invoice number."
                    >
                      <i class="el-icon-question" />
                    </el-tooltip>
                  </div>
                </div>
              </el-row>
              <el-row v-if="template_header.type === 'MEDIA'">
                <el-radio-group v-model="template_header.mediaType" :disabled="readOnly">
                  <el-radio label="IMAGE" border>Image</el-radio>
                  <el-radio label="VIDEO" border>Video</el-radio>
                  <el-radio label="DOCUMENT" border>Document</el-radio>
                </el-radio-group>
              </el-row>
            </el-row>
          </el-row>
        </el-card>
        <el-card>
          <el-row>
            <b>Body</b>
          </el-row>
          <el-row>
            <el-row>
              <span style="color: rgba(0, 0, 0, 0.55)"
                >Enter the text for your message in the language you've selected.</span
              >
            </el-row>
            <el-row>
              <el-input
                :disabled="readOnly"
                @keydown.ctrl.66.native="format('bold', $event)"
                @keydown.ctrl.73.native="format('italicize')"
                @keydown.ctrl.83.native="format('strikethrough')"
                @keydown.ctrl.77.native="format('monospace')"
                :rows="10"
                ref="message"
                v-model="template_body.text"
                type="textarea"
                :maxlength="maxLength.template_body"
                show-word-limit
              />
              <div v-if="!readOnly">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Bold (Ctrl-B)"
                  placement="top-start"
                >
                  <span class="fas fa-bold fa-lg" @click="format('bold')"></span>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Italics (Ctrl-I)"
                  placement="top-start"
                >
                  <span
                    class="fas fa-italic fa-lg"
                    @click="format('italicize')"
                    style="margin-left: 15px"
                  ></span>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Strikethrough (Ctrl-S)"
                  placement="top-start"
                >
                  <span
                    class="fas fa-strikethrough fa-lg"
                    @click="format('strikethrough')"
                    style="margin-left: 15px"
                  ></span>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Monospace (Ctrl-M)"
                  placement="top-start"
                >
                  <span
                    class="fas fa-code fa-lg"
                    @click="format('monospace')"
                    style="margin-left: 15px"
                  ></span>
                </el-tooltip>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  style="margin-left: 15px; margin-top: 5px"
                  @click="addVariable(template_body, 'text')"
                  >Add Variable</el-button
                >
              </div>
            </el-row>
            <el-row v-if="bodyTextWarnings.length > 0">
              <el-alert type="warning" show-icon :closable="false">
                <span>
                  <ul>
                    <li v-for="(warning, index) in bodyTextWarnings" :key="`warning-${index}`">
                      {{ warning }}
                    </li>
                  </ul>
                  <a
                    style="margin-left: 24px; color: blue"
                    href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
                    target="_blank"
                    >Learn more about formatting in Message Template Guidelines</a
                  >
                </span>
              </el-alert>
            </el-row>
          </el-row>
        </el-card>
        <el-card>
          <el-row>
            <b>Footer</b>
            <span
              style="
                background-color: lightgray;
                border-radius: 5px;
                padding: 2px;
                font-size: small;
                margin-left: 8px;
              "
              >Optional</span
            >
          </el-row>
          <el-row>
            <el-row>
              <span style="color: rgba(0, 0, 0, 0.55)"
                >Add a short line of text to the bottom of your message template.</span
              >
            </el-row>
            <el-row>
              <el-input
                :placeholder="readOnly ? '' : 'Enter footer text'"
                v-model="template_footer.text"
                :maxlength="maxLength.template_footer"
                show-word-limit
                :disabled="readOnly"
              />
            </el-row>
          </el-row>
        </el-card>
        <el-card>
          <el-row>
            <b>Buttons</b>
            <span
              style="
                background-color: lightgray;
                border-radius: 5px;
                padding: 2px;
                font-size: small;
                margin-left: 8px;
              "
              >Optional</span
            >
          </el-row>
          <el-row>
            <el-row>
              <span style="color: rgba(0, 0, 0, 0.55)"
                >Create buttons that let customers respond to your message or take action.</span
              >
            </el-row>
            <el-row>
              <el-row>
                <el-select
                  filterable
                  v-model="template_button_type"
                  placeholder="Select"
                  @change="handleButtonTypeSelection"
                  :disabled="readOnly"
                >
                  <el-option
                    v-for="type in buttonTypes"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value"
                  />
                </el-select>
                <el-tooltip
                  v-if="template_button_type === 'CALL_TO_ACTION' && template_buttons.length === 2"
                  placement="right"
                  content="To change the buttons order."
                >
                  <i
                    @click="swap(template_buttons)"
                    class="el-icon-refresh"
                    style="margin-left: 10px"
                  ></i>
                </el-tooltip>
              </el-row>
            </el-row>
            <el-row v-if="template_button_type === 'CALL_TO_ACTION'">
              <el-row v-for="(button, index) in template_buttons" :key="`button-${index}`">
                <el-row
                  style="
                    display: flex;
                    align-items: center;
                    background-color: #f8fafb;
                    border: 1px solid #dadde1;
                    padding: 10px;
                    border-radius: 10px;
                  "
                >
                  <el-row style="display: flex; flex-basis: 100%">
                    <div style="display: flex; flex-flow: column; flex-grow: 2">
                      <b>Type of Action</b>
                      <el-select
                        filterable
                        v-model="button.type"
                        placeholder="Select"
                        :disabled="readOnly || template_buttons.length === 2"
                      >
                        <el-option
                          v-for="type in callToActionButtonTypes"
                          :key="type.value"
                          :label="type.label"
                          :value="type.value"
                        />
                      </el-select>
                    </div>
                    <div style="display: flex; flex-flow: column; flex-grow: 5; margin-left: 10px">
                      <b>Button Text</b>
                      <el-input
                        v-model="button.text"
                        :maxlength="maxLength.template_button"
                        show-word-limit
                        :disabled="readOnly"
                      />
                    </div>
                    <div
                      v-if="button.type === 'URL'"
                      style="display: flex; flex-flow: column; flex-grow: 1; margin-left: 10px"
                    >
                      <b>URL Type</b>
                      <el-select
                        filterable
                        v-model="button.urltype"
                        placeholder="Select"
                        :disabled="readOnly"
                      >
                        <el-option
                          v-for="type in buttonUrlTypes"
                          :key="type.value"
                          :label="type.label"
                          :value="type.value"
                        />
                      </el-select>
                    </div>
                    <div style="display: flex; flex-flow: column; flex-grow: 27; margin-left: 10px">
                      <div class="phone-number-container" v-if="button.type === 'PHONE_NUMBER'">
                        <b>Phone Number</b>
                        <el-input
                          v-model="button.phone_number"
                          :maxlength="maxLength.template_phonenumber"
                          show-word-limit
                          :disabled="readOnly"
                        >
                          <el-select
                            v-model="button.country_code"
                            slot="prepend"
                            placeholder="Select"
                            :disabled="readOnly"
                            filterable
                          >
                            <el-option
                              v-for="countryCode in countryCodes"
                              :key="countryCode.code"
                              :label="`${countryCode.name} (${countryCode.dial_code})`"
                              :value="countryCode.dial_code"
                            ></el-option>
                          </el-select>
                        </el-input>
                      </div>
                      <div v-else-if="button.type === 'URL'">
                        <b>Website URL</b>
                        <el-form :model="button" :rules="rules">
                          <el-form-item prop="url">
                            <el-input
                              v-model="button.url"
                              :maxlength="maxLength.template_url"
                              show-word-limit
                              :disabled="readOnly"
                              placeholder="https://www.example.com"
                            />
                          </el-form-item>
                        </el-form>
                      </div>
                    </div>
                    <div
                      v-if="button.type === 'URL' && button.urltype === 'DYNAMIC'"
                      style="
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        margin-left: 10px;
                        margin-top: 12px;
                      "
                    >
                      {{ "\{\{1\}\}" }}
                    </div>
                    <div
                      v-if="button.type === 'URL' && button.urltype === 'DYNAMIC'"
                      style="
                        display: flex;
                        flex-flow: column;
                        justify-content: center;
                        margin-top: 16px;
                      "
                    >
                      <el-popover width="300" trigger="hover" placement="bottom-end">
                        <i
                          slot="reference"
                          class="el-icon-info"
                          style="margin-left: 10px; color: grey"
                        />
                        <span style="word-break: break-word">
                          Adding a variable creates a personalized link for the customer to view
                          their info. Only one variable can be added to the end of a URL.
                          <a
                            style="color: blue"
                            href="https://business.facebook.com/business/help/2055875911147364?id=2129163877102343"
                            target="_blank"
                            >Learn More</a
                          >
                        </span>
                      </el-popover>
                    </div>
                  </el-row>
                  <i
                    @click="deleteButton(template_buttons, index)"
                    class="el-icon-close"
                    style="margin-left: 10px"
                  ></i>
                </el-row>
              </el-row>
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  :disabled="template_buttons.length >= 2 || readOnly"
                  @click="addButton(template_button_type, template_buttons)"
                  >Add Another Button</el-button
                >
                <el-tooltip placement="bottom" :content="addButtonDetails.action">
                  <i class="el-icon-info" style="margin-left: 10px; color: grey" />
                </el-tooltip>
              </el-row>
            </el-row>
            <el-row v-else-if="template_button_type === 'QUICK_REPLY'">
              <el-row v-for="(button, index) in template_buttons" :key="`button-${index}`">
                <el-row style="display: flex; flex-direction: column">
                  <b>Button Text</b>
                  <el-row style="display: flex; margin-top: 5px; align-items: center">
                    <el-input
                      v-model="button.text"
                      style="width: 280px"
                      :maxlength="maxLength.template_button"
                      show-word-limit
                      :disabled="readOnly"
                    />
                    <i
                      @click="deleteButton(template_buttons, index)"
                      class="el-icon-close"
                      style="margin-left: 10px"
                    ></i>
                  </el-row>
                </el-row>
              </el-row>
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  :disabled="template_buttons.length >= 3 || readOnly"
                  @click="addButton(template_button_type, template_buttons)"
                  >Add Another Button</el-button
                >
                <el-tooltip placement="bottom" :content="addButtonDetails.quickreply">
                  <i class="el-icon-info" style="margin-left: 10px; color: grey" />
                </el-tooltip>
              </el-row>
            </el-row>
          </el-row>
        </el-card>
      </el-row>
      <el-row>
        <slot
          name="footer"
          :template-name="template_name"
          :category="template_category"
          :language="template_language"
          :header="template_header"
          :body="template_body"
          :footer="template_footer"
          :buttons="template_buttons"
          :warningExists="warningExists"
        ></slot>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import countryCodes from "@/helperMethods/countryCodes";

export default {
  name: "MessageTemplateForm",
  components: {},
  props: {
    addingNewLanguage: Boolean,
    selectedLanguages: Array,
    name: String,
    language: String,
    category: String,
    header: Object,
    body: Object,
    footer: Object,
    buttons: Array,
    buttonType: String,
    readOnly: Boolean,
    status: String,
  },
  data() {
    const checkUrl = (rule, value, cb) => {
      const validUrl = /(https:\/\/|http:\/\/)\S+/.test(value);
      if (!validUrl) {
        cb(new Error("Please provide valid url."));
      } else {
        cb();
      }
    };
    return {
      rules: {
        url: [{ validator: checkUrl, trigger: "blur" }],
      },
      template_name: this.name,
      template_language: this.language,
      template_category: this.category,
      template_header: this.header,
      template_body: this.body,
      template_footer: this.footer,
      template_buttons: this.buttons,
      template_button_type: this.buttonType,
      template_status: this.status,
      headerTypes: [
        { label: "None", value: "" },
        { label: "Text", value: "TEXT" },
        { label: "Media", value: "MEDIA" },
      ],
      buttonTypes: [
        { label: "None", value: "" },
        { label: "Call To Action", value: "CALL_TO_ACTION" },
        { label: "Quick Reply", value: "QUICK_REPLY" },
      ],
      callToActionButtonTypes: [
        { label: "Call Phone Number", value: "PHONE_NUMBER" },
        { label: "Visit Website", value: "URL" },
      ],
      buttonUrlTypes: [
        { label: "Static", value: "STATIC" },
        { label: "Dynamic", value: "DYNAMIC" },
      ],
      countryCodes,
      languages: [
        { label: "Afrikaans", value: "af" },
        { label: "Albanian", value: "sq" },
        { label: "Arabic", value: "ar" },
        { label: "Azerbaijani", value: "az" },
        { label: "Bengali", value: "bn" },
        { label: "Bulgarian", value: "bg" },
        { label: "Catalan", value: "ca" },
        { label: "Chinese (CHN)", value: "zh_CN" },
        { label: "Chinese (HKG)", value: "zh_HK" },
        { label: "Chinese (TAI)", value: "zh_TW" },
        { label: "Croatian", value: "hr" },
        { label: "Czech", value: "cs" },
        { label: "Danish", value: "da" },
        { label: "Dutch", value: "nl" },
        { label: "English", value: "en" },
        { label: "English (UK)", value: "en_GB" },
        { label: "English (US)", value: "en_US" },
        { label: "Estonian", value: "et" },
        { label: "Filipino", value: "fil" },
        { label: "Finnish", value: "fi" },
        { label: "French", value: "fr" },
        { label: "German", value: "de" },
        { label: "Greek", value: "el" },
        { label: "Gujarati", value: "gu" },
        { label: "Hebrew", value: "he" },
        { label: "Hindi", value: "hi" },
        { label: "Hungarian", value: "hu" },
        { label: "Indonesian", value: "id" },
        { label: "Irish", value: "ga" },
        { label: "Italian", value: "it" },
        { label: "Japanese", value: "ja" },
        { label: "Kannada", value: "kn" },
        { label: "Kazakh", value: "kk" },
        { label: "Korean", value: "ko" },
        { label: "Lao", value: "lo" },
        { label: "Latvian", value: "lv" },
        { label: "Lithuanian", value: "lt" },
        { label: "Macedonian", value: "mk" },
        { label: "Malay", value: "ms" },
        { label: "Marathi", value: "mr" },
        { label: "Norwegian", value: "nb" },
        { label: "Persian", value: "fa" },
        { label: "Polish", value: "pl" },
        { label: "Portuguese (BR)", value: "pt_BR" },
        { label: "Portuguese (POR)", value: "pt_PT" },
        { label: "Punjabi", value: "pa" },
        { label: "Romanian", value: "ro" },
        { label: "Russian", value: "ru" },
        { label: "Serbian", value: "sr" },
        { label: "Slovak", value: "sk" },
        { label: "Slovenian", value: "sl" },
        { label: "Spanish", value: "es" },
        { label: "Spanish (ARG)", value: "es_AR" },
        { label: "Spanish (SPA)", value: "es_ES" },
        { label: "Spanish (MEX)", value: "es_MX" },
        { label: "Swahili", value: "sw" },
        { label: "Swedish", value: "sv" },
        { label: "Tamil", value: "ta" },
        { label: "Telugu", value: "te" },
        { label: "Thai", value: "th" },
        { label: "Turkish", value: "tr" },
        { label: "Ukrainian", value: "uk" },
        { label: "Urdu", value: "ur" },
        { label: "Uzbek", value: "uz" },
        { label: "Vietnamese", value: "vi" },
      ],
      categories: [
        {
          label: "Transactional",
          value: "TRANSACTIONAL",
          details:
            "Send account updates, order updates, alerts, and more to share important information.",
        },
        {
          label: "Marketing",
          value: "MARKETING",
          details:
            "Send promotional offers, product announcements, and more to increase awareness and engagement.",
        },
        {
          label: "One-time passwords",
          value: "OTP",
          details: "Send codes that allow your customers to access their accounts.",
        },
      ],
      languagePolicies: [
        {
          label: "Deterministic",
          value: "deterministic",
        },
        {
          label: "Fallback",
          value: "fallback",
        },
      ],
      maxLength: {
        template_name: 512,
        template_header: 60,
        template_body: 1024,
        template_footer: 60,
        template_button: 25,
        template_phonenumber: 20,
        template_url: 2000,
      },
      addButtonDetails: {
        action: "Maximum 2 buttons, each button will be different action.",
        quickreply: "Maximum 3 buttons.",
      },
    };
  },
  computed: {
    filteredLanguages() {
      if (this.addingNewLanguage) {
        return this.languages.filter((lang) => !this.selectedLanguages.includes(lang.value));
      }
      return this.languages;
    },
    templateCategory: {
      get() {
        const categoryList = this.categories.map((category) => category.value);
        if (this.template_category && !categoryList.includes(this.template_category)) {
          return "OTHER";
        }
        return this.template_category;
      },
      set(val) {
        this.template_category = val;
      },
    },
    templateName: {
      get() {
        return this.template_name;
      },
      set(val) {
        this.template_name = val.replace(/\s/g, "_");
      },
    },
    statusIndicator() {
      switch (this.template_status) {
        case "APPROVED":
          return "success";
        case "PENDING":
          return "warning";
        case "REJECTED":
          return "danger";
        default:
          return "info";
      }
    },
    otherTemplateCategory() {
      return this.readOnly && this.templateCategory === "OTHER";
    },
    bodyTextWarnings() {
      const warnings = [];
      const startWithParameter = /^{{(\d+)}}/.test(this.template_body.text);
      const endWithParameter = /{{(\d+)}}$/.test(this.template_body.text);
      if (startWithParameter || endWithParameter) {
        warnings.push(
          "The body text contains variable parameters at the beginning or end. Check to make sure this is the correct format."
        );
      }
      const wordsMatched = this.template_body.text?.match(/\S+/g);
      const lessThanEqualTwoWords = !wordsMatched || wordsMatched.length <= 2;
      const hasParameter = /{{(\d+)}}/.test(this.template_body.text);
      if (lessThanEqualTwoWords && hasParameter) {
        warnings.push(
          "The body text contains too many variable parameters relative to the length of the message. Check to make sure this is the correct format."
        );
      }
      return warnings;
    },
    warningExists() {
      const validUrl = (value) => /(https:\/\/|http:\/\/)\S+/.test(value);
      const buttonInvalid = !!this.template_buttons.find(
        (button) => (button.type === "URL" && !validUrl(button?.url)) || button?.text?.length === 0
      );
      return this.bodyTextWarnings.length > 0 || buttonInvalid;
    },
  },
  mounted() {},
  methods: {
    addButton(type, buttons) {
      if (type === "CALL_TO_ACTION") {
        const existingButtonURL = buttons.find((button) => button.type === "URL");
        if (existingButtonURL) {
          buttons.push({
            type: "PHONE_NUMBER",
            text: "",
            phone_number: "",
          });
        } else {
          buttons.push({
            type: "URL",
            text: "",
            url: "",
            urltype: "STATIC",
          });
        }
      } else if (type === "QUICK_REPLY") {
        buttons.push({
          type: "QUICK_REPLY",
          text: "",
        });
      }
      this.$forceUpdate();
    },
    deleteButton(buttons, index) {
      if (this.readOnly) return;
      buttons.splice(index, 1);
    },
    swap(buttons) {
      buttons.push(buttons[0]);
      buttons.shift();
    },
    isButtonsOutOfLimit(buttons, limit) {
      return buttons.length >= limit;
    },
    handleButtonTypeSelection(currentValue) {
      this.template_buttons.length = 0;
      if (currentValue === "CALL_TO_ACTION") {
        this.template_buttons.push({
          type: "PHONE_NUMBER",
          text: "",
          phone_number: "",
        });
      } else if (currentValue === "QUICK_REPLY") {
        this.template_buttons.push({
          type: "QUICK_REPLY",
          text: "",
        });
      }
    },
    addVariable(obj, key) {
      let message;
      if (!obj) {
        message = this[key];
      } else {
        message = obj[key];
      }

      if (typeof message !== "string") message = "";
      let messagesWithParameters = message.match(/{{(\d+)}}/g);

      if (!messagesWithParameters) {
        if (message.length > 0 && message.indexOf(" ", message.length - 1) === -1) {
          message += " {{1}}";
        } else {
          message += "{{1}}";
        }
      } else {
        const sortedParams = messagesWithParameters
          .map((param) => {
            return parseInt(param.replace("{{", "").replace("}}", ""));
          })
          .sort((a, b) => a - b);
        sortedParams.forEach((paramNo, index) => {
          if (paramNo !== index + 1) {
            message = message.replace(`{{${paramNo}}}`, `{{${index + 1}}}`);
          }
        });
        if (message.indexOf(" ", message.length - 1) === -1) {
          message += ` {{${sortedParams.length + 1}}}`;
        } else {
          message += `{{${sortedParams.length + 1}}}`;
        }
      }

      if (!obj) {
        this[key] = message;
      } else {
        this.$set(obj, key, message);
      }
    },
    format(formatType, event) {
      if (event) {
        event.preventDefault();
      }
      const input = this.$refs.message.getInput();
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const left = input.value.substring(0, start);
      let sub = `${input.value.substring(start, end)}`;
      // https://faq.whatsapp.com/en/android/26000002/
      // Format according to whatsapp formatting syntax
      switch (formatType) {
        case "bold":
          sub = `*${sub}*`;
          break;
        case "italicize":
          sub = `_${sub}_`;
          break;
        case "strikethrough":
          sub = `~${sub}~`;
          break;
        case "monospace":
          sub = "```" + sub + "```";
          break;
      }
      const right = input.value.substring(end);
      input.value = left + sub + right;
      this.template_body.text = input.value;
    },
  },
  watch: {
    name: function () {
      this.template_name = this.name;
    },
    category: function () {
      this.template_category = this.category;
    },
    language: function () {
      this.template_language = this.language;
    },
    header: function () {
      this.template_header = this.header;
    },
    body: function () {
      this.template_body = this.body;
    },
    footer: function () {
      this.template_footer = this.footer;
    },
    buttons: function () {
      this.template_buttons = this.buttons;
    },
    buttonType: function () {
      this.template_button_type = this.buttonType;
    },
    status: function () {
      this.template_status = this.status;
    },
  },
};
</script>

<style>
.phone-number-container .el-input-group__prepend {
  width: 20%;
}
</style>
