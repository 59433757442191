<template>
  <div class="form">
    <div aria-modal="true" role="presentation" width="100%" style="padding: 15px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0 15px 0;
        "
      >
        <span style="font-size: 24px; font-weight: bold">Agent Webchat Form</span>

        <div>
          <el-button
            plain
            type="danger"
            @click="$emit('toggleFormData')"
            size="mini"
            :disabled="isSubmittingForm"
            >Cancel</el-button
          >
          <el-button
            plain
            type="success"
            size="mini"
            @click="submitForm"
            :disabled="disableForm || isSubmittingForm"
            :loading="isSubmittingForm"
            >Submit</el-button
          >
        </div>
      </div>
      <el-form
        :model="selectedFormData"
        :rules="rules"
        ref="visitorProfileForm"
        label-position="right"
        label-width="140px"
        display="inline-block"
        size="mini"
      >
        <el-form-item prop="customerName" label="Customer Name:">
          <el-input
            v-model="selectedFormData.customerName"
            autocomplete="off"
            @blur="updateForm('customerName')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="customerId" label="NRIC/FIN/WP:">
          <el-input
            v-model="selectedFormData.customerId"
            autocomplete="off"
            @blur="updateForm('customerId')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="customerProfile" label="Customer Profile:">
          <FieldAutocomplete
            v-on:input="updateForm('customerProfile', $event)"
            field-name="customerProfile"
            v-model="selectedFormData.customerProfile"
          />
        </el-form-item>
        <el-form-item prop="interactionType" label="Interaction Type:">
          <FieldAutocomplete
            v-on:input="updateForm('interactionType', $event)"
            field-name="interactionType"
            v-model="selectedFormData.interactionType"
          />
        </el-form-item>
        <el-form-item prop="issueCategorization" label="Issue Categorization:">
          <FieldAutocomplete
            v-on:input="updateForm('issueCategorization', $event)"
            field-name="issueCategorization"
            v-model="selectedFormData.issueCategorization"
          />
        </el-form-item>
        <el-form-item prop="issueDescription" label="Issue Description:">
          <el-input
            type="textarea"
            v-model="selectedFormData.issueDescription"
            autocomplete="off"
            @blur="updateForm('issueDescription')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item label="Employer Involved:">
          <el-input
            v-model="selectedFormData.employerInvolved"
            autocomplete="off"
            @blur="updateForm('employerInvolved')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="enquirerCompany" label="Enquirer's Company:">
          <el-input
            v-model="selectedFormData.enquirerCompany"
            :disabled="disableForm || isValidatingUen"
            @blur="updateForm('enquirerCompany')"
          >
            <i v-if="isValidatingUen" class="el-icon-loading el-input__icon" slot="suffix"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="Mobile Phone:">
          <el-input
            v-model="selectedFormData.mobilePhone"
            autocomplete="off"
            @blur="updateForm('mobilePhone')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item label="Home Phone:">
          <el-input
            v-model="selectedFormData.homePhone"
            autocomplete="off"
            @blur="updateForm('homePhone')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item label="Office Phone:">
          <el-input
            v-model="selectedFormData.officePhone"
            autocomplete="off"
            @blur="updateForm('officePhone')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="actionDetails" label="Action Details:">
          <el-input
            type="textarea"
            v-model="selectedFormData.actionDetails"
            autocomplete="off"
            @blur="updateForm('actionDetails')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="agentName" label="Agent Name:">
          <el-input
            v-model="selectedFormData.agentName"
            autocomplete="off"
            @blur="updateForm('agentName')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
        <el-form-item prop="agentSoeId" label="Agent SOE ID:">
          <el-input
            v-model="selectedFormData.agentSoeId"
            autocomplete="off"
            @blur="updateForm('agentSoeId')"
            :disabled="disableForm"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isNricValid, isWorkPermitValid } from "@/helperMethods/util";
import FieldAutocomplete from "./FieldAutocomplete";
import axios from "axios";
import _ from "lodash";

const ALREADY_SUBMITTED_EMAIL_ERROR = "User has already submitted";
const LiveChatStatus = {
  PENDING: "pending",
  ATTENDING: "attending",
};

const DATAGOVAPI = {
  ENDPOINT: "https://lambda.keyreply.com/api/momUEN/?q=",
  // "https://data.gov.sg/api/action/datastore_search?limit=5&resource_id=39201285-b73e-487a-a971-3a12d34ab8d9&q=",
};

export default {
  components: {
    FieldAutocomplete,
  },
  props: {
    formData: Object,
  },
  name: "VisitorProfile",
  data() {
    const nricCheck = (rule, value, callback) => {
      try {
        if (value.length !== 9 && value.length !== 10) {
          callback(new Error(this.lengthCheck()));
          return false;
        }
      } catch (error) {
        //this implicitly catches a null string (empty field)
        callback(new Error(this.requiredInputField));
        return false;
      }
      if (isNricValid(value) || isWorkPermitValid(value)) {
        callback();
      } else {
        callback(
          new Error(
            `Please fill in a valid NRIC/FIN/WP.
            E.g. S9194365D for NRIC/FIN and O 58101234 for WP.`
          )
        );
        return false;
      }

      const firstChar = value.slice(0, 1);
      if (!firstChar.match(/[STFG8-9]/)) {
        callback(
          new Error("NRIC/FIN/WP should start with either 'S', 'T', 'F' or 'G', '8' or '9'.")
        );
        return false;
      }

      const numbers = value.slice(1, -1);
      if (isNaN(numbers)) {
        callback(new Error(this.invalidInputMessage));
        return false;
      }
      callback();
    };

    const nameCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.requiredInputField));
        return false;
      } else if (value.length >= 66) {
        callback(new Error("Too many characters. Please enter a valid name."));
      }
      callback();
    };

    const requiredCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.requiredInputField));
        return false;
      }
      callback();
    };

    const uenValidation = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.requiredInputField));
        return false;
      } else if (value === "NA00000") {
        callback();
      } else if (value.length < 9) {
        callback(new Error("Invalid UEN number inserted."));
        return false;
      } else {
        this.isValidatingUen = true;
        const _endpoint = `${DATAGOVAPI.ENDPOINT}${value}`;
        axios
          .get(_endpoint)
          .then((response) => {
            const _records = _.get(response, "data.result.records", []);
            const _hasRecord = _records.findIndex((_record) => _record.uen === value) > -1;
            if (_hasRecord) {
              callback();
            } else {
              callback(new Error("Invalid UEN number inserted."));
              return false;
            }
          })
          .catch((error) => {
            callback(new Error("Unable to validate UEN number at this moment."));
            return false;
          })
          .finally(() => (this.isValidatingUen = false));
      }
    };

    return {
      isValidatingUen: false,
      visitorProfileForm: {
        customerName: "",
        customerId: "",
        customerProfile: "",
        interactionType: "",
        issueCategorization: "",
        issueDescription: "",
        employerInvolved: "",
        enquirerCompany: "",
        mobilePhone: "",
        homePhone: "",
        officePhone: "",
        actionDetails: "",
        agentName: "",
        agentSoeId: "",
      },
      isSubmittingForm: false,
      selectedFormData: this.formData,
      lengthCheck: () => {
        return `This field should be 9 or 10 characters long.`;
      },
      invalidInputMessage: "Please check the format of the value entered.",
      requiredInputField: "This field cannot be left blank.",
      rules: {
        customerName: [
          {
            required: true,
            validator: nameCheck,
            trigger: "blur",
          },
        ],
        enquirerCompany: [
          {
            required: true,
            validator: uenValidation,
            trigger: "blur",
          },
        ],
        customerId: [
          {
            required: true,
            validator: nricCheck,
            trigger: "blur",
          },
        ],
        customerProfile: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        interactionType: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        issueCategorization: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        issueDescription: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        actionDetails: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        agentName: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
        agentSoeId: [
          {
            required: true,
            validator: requiredCheck,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["selectedChat", "fieldDataForAutoFormPopulation", "dataCollectionFormEnabled"]),
    disableForm() {
      return this.selectedChat.resolved;
    },
    inPostChatWorkMode() {
      const selectChatStatus = this.selectedChat.status;
      return (
        selectChatStatus !== LiveChatStatus.ATTENDING && selectChatStatus !== LiveChatStatus.PENDING
      );
    },
  },
  methods: {
    submitForm() {
      if (!this.inPostChatWorkMode) {
        this.$notify.error({
          title: "Error",
          message: "Cannot submit until you have ended the chat",
          position: "bottom-right",
        });
        return;
      }
      this.$refs.visitorProfileForm.validate((valid) => {
        if (valid) {
          this.processSubmitFormData();
        } else {
          return false;
        }
      });
    },
    async processSubmitFormData() {
      if (!this.isSubmittingForm) {
        this.isSubmittingForm = true;
        const chat = this.selectedChat;
        const visitorProfileForm = Object.assign(
          {},
          this.visitorProfileForm,
          this.selectedFormData,
          {
            status: chat.status,
          }
        );
        const submitFormData = await this.$store
          .dispatch("SUBMIT_FORM_DATA", {
            chat,
            visitorProfileForm,
          })
          .then((result) => {
            const { error, success } = _.get(result, "data.livechatAPI.formDataSubmitted", {});

            if (error) {
              this.$notify.error({
                title: "Error",
                message: error,
                position: "bottom-right",
              });

              // Still process resolving livechat if email is already submitted.
              if (error !== ALREADY_SUBMITTED_EMAIL_ERROR) {
                return;
              }
            }

            const resolveChatPayload = {
              sessionPartitionKey: _.get(chat, "PartitionKey", ""),
              userId: chat.user_id,
              sessionRowKey: chat.RowKey,
            };
            this.$store
              .dispatch("END_SESSION_FOR_AGENT", resolveChatPayload)
              .then((isResolved) => {
                if (!isResolved) {
                  this.$notify.error({
                    title: "Error",
                    message: "Encountered error resolving chat",
                    position: "bottom-right",
                  });
                }
              })
              .finally(() => {
                this.isSubmittingForm = false;
              });
          })
          .catch((error) => {
            this.$notify.error({
              title: "Error",
              message: "Encountered error resolving chat",
              position: "bottom-right",
            });
          })
          .finally(() => {
            this.isSubmittingForm = false;
          });
      }
    },
    updateForm(field, data = "") {
      let value = data || event.target.value;
      if (field === "customerId") {
        value = String(value).toUpperCase();
        this.selectedFormData.customerId = value;
      }
      if (field === "enquirerCompany") {
        value = String(value).toUpperCase();
        this.selectedFormData.enquirerCompany = value;
      }
      const updatedData = {
        formData: { [field]: value },
      };
      const updatedLivechat = Object.assign({}, this.selectedChat, {
        formData: this.selectedFormData,
      });
      this.$store.commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
    },
  },
};
</script>

<style scoped>
.form {
  background-color: rgb(255, 247, 247);
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
}

.el-form-item {
  margin-bottom: 1.5em;
}

.el-input {
  display: inline-block;
  width: auto;
}
</style>
