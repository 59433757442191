import Vue from "vue";
import Router from "./helpers/routing";
import store from "@/store";
import Home from "@/views/Home/Index.vue";
import Subs from "@/views/Subs.vue";
import LiveChat from "@/views/Livechat/Index.vue";
import AccessControl from "@/views/AccessControl/Index.vue";
import WebWidget from "@/views/WebWidget.vue";
import Editor from "@/views/Editor/Index.vue";
import Entities from "@/views/Entities/Index.vue";
import Broadcast from "@/views/Broadcast.vue";
import Analytics from "@/views/Analytics/Index.vue";
import Supervisor from "@/views/Supervisor/Index.vue";
import Audit from "@/views/Audit.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import Visitors from "@/views/Visitors/Index.vue";
import FileStorage from "@/views/FileStorage/Index.vue";
import Faq from "@/views/FAQ.vue";
import Faq2 from "@/views/FAQ 2.0.vue";
import Logout from "@/views/Logout.vue";
import ModuleEditor from "@/components/ModuleEditor/Index.vue";
import ImportExport from "@/views/ImportExport.vue";
import SuperAdmin from "@/views/SuperAdmin/Index.vue";
import Watson from "@/views/Watson.vue";
import WhatsApp from "@/views/WhatsApp.vue";
import ChatForm from "@/views/ChatForm.vue";
import Prism from "@/views/Prism/Index.vue";
import Evaluation from "@/views/Evaluation/Index.vue";

import { getInstance } from "@/auth";
import _ from "lodash";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/broadcast",
      component: Broadcast,
      name: "broadcast",
      children: [
        {
          path: ":timestamp",
          component: Broadcast,
          name: "broadcast-timestamp",
        },
      ],
    },
    {
      path: "/livechat/:sessionId?",
      component: LiveChat,
      name: "livechat",
    },
    {
      path: "/access-control",
      component: AccessControl,
      name: "access_control",
    },
    {
      path: "/file-storage",
      component: FileStorage,
      name: "file-storage",
    },
    {
      path: "/widget",
      component: WebWidget,
      name: "webchat",
    },
    {
      path: "/subs",
      component: Subs,
      name: "subscription",
      children: [
        {
          path: ":date",
          component: Subs,
          name: "subscription-data",
        },
      ],
    },
    {
      path: "/error/:type",
      component: ErrorPage,
      name: "error",
      props: true,
    },
    {
      path: "/editor",
      component: Editor,
      name: "editor",
    },
    {
      path: "/editor/flow",
      component: Editor,
      name: "editor-flow",
    },
    {
      path: "/editor/integrations",
      component: Editor,
      name: "editor-integrations",
    },
    {
      path: "/editor/form",
      component: Editor,
      name: "editor-form",
    },
    {
      path: "/entities",
      component: Entities,
      name: "entities",
    },
    {
      path: "/analytics",
      component: Analytics,
      name: "analytics",
    },
    {
      path: "/supervisor",
      component: Supervisor,
      name: "supervisor",
    },
    {
      path: "/audit",
      component: Audit,
      name: "audit",
    },
    {
      path: "/settings",
      component: ModuleEditor,
      name: "settings",
    },
    {
      path: "/faq",
      component: Faq,
      name: "faq",
    },
    {
      path: "/faq2",
      component: Faq2,
      name: "faq2",
    },
    {
      path: "/visitors",
      component: Visitors,
      name: "visitors",
    },
    {
      path: "/chat-form",
      component: ChatForm,
      name: "chat_form",
    },
    {
      path: "/prism",
      component: Prism,
      name: "prism",
    },
    {
      path: "/",
      component: Home,
      name: "home",
    },
    {
      path: "/faqw",
      component: Watson,
      name: "faqw",
    },
    {
      path: "/whatsapp",
      component: WhatsApp,
      name: "whatsapp",
    },
    {
      path: "/evaluation",
      component: Evaluation,
      name: "evaluation",
    },
    {
      path: "/import_export",
      component: ImportExport,
      name: "import_export",
    },
    {
      path: "/superadmin",
      component: SuperAdmin,
      name: "superadmin",
    },
    {
      path: "/logout",
      component: Logout,
      name: "logout",
    },
    {
      path: "*",
      redirect: "/error/not-found",
    },
  ],
});

router.beforeEach(async (to: any, from: any, next: any) => {
  if (to.name === from.name) {
    return;
  }

  const authService = getInstance();

  if (!authService.authType) {
    await authService.getAuthType();
  }

  if (to.name === "logout" && authService.authType === "KEYCLOAK") {
    router.push("/").catch((_) => {});
    return;
  }

  // aliasing because these three path actually refers to "editor" modules
  const pathAliasList = [
    {
      original: "editor-flow",
      alias: "editor",
    },
    {
      original: "editor-integrations",
      alias: "editor",
    },
    {
      original: "editor-form",
      alias: "editor",
    },
  ];
  const pathAlias = _.find(pathAliasList, (path) => path.original === to.name);
  const toPath = pathAlias ? pathAlias.alias : to.name;

  const proceedPermissionCheck = () => {
    const access_control = store.state.modules["access_control"];
    if (!access_control || !access_control.enabled) {
      next();
    } else {
      performAccessControl(toPath, next);
    }
  };

  if (from.name === null) {
    store.watch(
      (state) => state.loaded,
      () => {
        proceedPermissionCheck();
      }
    );
  }
  proceedPermissionCheck();
});

// Function that performs access-controlled routing
function performAccessControl(module: any, next: any) {
  if (module === "error") {
    next();
  } // error page can bypass access control

  // if no roles, send to error page
  const roles = store.state.profile.app_metadata.roles;
  if (!roles || roles.length === 0) {
    router.push({ path: "/error/session-timeout" });
  }

  const isSuperadmin = _.chain(store.state).get("profile.email").endsWith("@keyreply.com").value();

  //Added in null, as it does not redirect to homepage on "/" for Keycloak
  const openLinks = ["home", "interactions", "introspect", "error", "login", "logout", null]; // links that are not access controlled
  if (
    (openLinks as any).includes(module) ||
    isSuperadmin ||
    checkRoles(getPermittedRoles(module), false)
  ) {
    next();
  } else {
    router.push({ path: "/error/no-permission" }).catch(() => {});
    next(new Error("You do not have permission for this page."));
  }
}

export default router;
