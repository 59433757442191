import Cohere from "cohere-js";

/**
 * @description Init cohere package
 * @return {void}
 */
export function initCohere() {
  // if (isLocalhost()) {
  //   return;
  // }

  Cohere.init("8v-r9cb1ex3MV_K47-CQrGH7");
}

export function enrichCohereUserData(user: any) {
  if (user) {
    Cohere.identify(
      user.email, // Required: can be any unique ID
      {
        displayName: user.name,
        email: user.email,
      }
    );
  }
}
