<template>
  <div>
    <el-card>
      <h3 slot="header">Inactivity Manager</h3>

      <div style="margin-bottom: 20px; margin-left: 5px">
        <p>
          <small>
            List of content nodes that the inactivity will ignore.
            <br />If the last interaction in the session matched with one of the content node, the
            inactivity prompt will be removed.
          </small>
        </p>
        <el-select
          v-model="value.inactivityManager.ignore"
          multiple
          allow-create
          filterable
          style="max-width: 600px; width: 100%"
          :multiple-limit="20"
          placeholder="Add content node"
        >
          <template v-if="value.inactivityManager.ignore">
            <el-option
              v-for="(item, index) in value.inactivityManager.ignore"
              :key="`inactivity-ignore-${item}-${index}`"
              :label="item"
              :value="item"
            >
            </el-option>
          </template>
        </el-select>
      </div>

      <el-row :gutter="15">
        <el-col :span="8">
          <Bot :value="value" />
        </el-col>
        <el-col :span="8">
          <Livechat :value="value" />
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Bot from "./Bot";
import Livechat from "./Livechat";

export default {
  name: "InactivityManager",
  components: {
    Bot,
    Livechat,
  },
  props: ["setting", "value", "modules"],
  beforeMount() {
    if (!this.value.inactivityManager) {
      this.$set(this.value, "inactivityManager", {});
    }

    if (!this.value.inactivityManager.bot) {
      this.$set(this.value.inactivityManager, "bot", this.setting.default.bot);
    }

    if (!this.value.inactivityManager.livechat) {
      this.$set(this.value.inactivityManager, "livechat", this.setting.default.livechat);
    }

    if (!this.value.inactivityManager.livechatAgent) {
      this.$set(this.value.inactivityManager, "livechatAgent", this.setting.default.livechatAgent);
    }

    if (!this.value.inactivityManager.bot.secondPromptThreshold) {
      this.$set(
        this.value.inactivityManager.bot,
        "secondPromptThreshold",
        this.setting.default.bot.secondPromptThreshold
      );
    }

    if (!this.value.inactivityManager.livechat.thresholdSecondPrompt) {
      this.$set(
        this.value.inactivityManager.livechat,
        "thresholdSecondPrompt",
        this.setting.default.livechat.thresholdSecondPrompt
      );
    }

    if (!this.value.inactivityManager.livechat.autoResolveWait) {
      this.$set(
        this.value.inactivityManager.livechat,
        "autoResolveWait",
        this.setting.default.livechat.autoResolveWait
      );
    }

    if (!this.value.inactivityManager.livechat.autoResolveWaitSecondPrompt) {
      this.$set(
        this.value.inactivityManager.livechat,
        "autoResolveWaitSecondPrompt",
        this.setting.default.livechat.autoResolveWaitSecondPrompt
      );
    }
  },
};
</script>
