<template>
  <div class="hidden-sm-and-down editor-header">
    <BotSelector :setStatus="setStatus" :reloading="reloading" :switchBot="switchBot" />

    <RollBackSelector :setStatus="setStatus" :reloadBot="reloadBot" />

    <SaveAll :setStatus="setStatus" :saving="saving" />

    <BotCreator :setStatus="setStatus" :creating="creating" />

    <el-tooltip slot="reference" class="item" effect="dark" content="Reload bot" placement="bottom">
      <el-button
        circle
        :loading="reloading"
        size="small"
        :plain="true"
        type="plain"
        icon="el-icon-refresh"
        @click="reloadBot"
      />
    </el-tooltip>

    <BotDeletor :setStatus="setStatus" :deleting="deleting" />
  </div>
</template>

<script>
import BotSelector from "./BotSelector";
import BotCreator from "./BotCreator";
import BotDeletor from "./BotDeletor";
import RollBackSelector from "./RollbackSelector";
import SaveAll from "./SaveAll";

export default {
  name: "EditorHeader",
  components: {
    BotSelector,
    BotCreator,
    BotDeletor,
    RollBackSelector,
    SaveAll,
  },
  props: [],
  data() {
    return {
      saving: false,
      reloading: false,
      deleting: false,
      creating: false,
    };
  },
  mounted() {
    this.$store.dispatch("FETCH_BOT_LIST");
    this.$nextTick(function () {
      const mkckEnabled = _.get(this, "$store.state.modules['makerChecker'].enabled");
      if (mkckEnabled) {
        const refreshSameBot = true;
        this.reloadBot(refreshSameBot);
      }
    });
  },
  methods: {
    setStatus(name, status) {
      this[name] = status;
    },
    reloadBot(refreshSameBot = false) {
      this.switchBot(this.$store.state.brain, refreshSameBot);
    },
    switchBot(name, refreshSameBot) {
      this.setStatus("reloading", true);
      if (this.$store.state.brain !== name) {
        this.$store.state.nodes.changes = {};
      }
      this.$store
        .dispatch("SWITCH_BOT", name)
        .then(() => {
          this.setStatus("reloading", false);
          if (!refreshSameBot) {
            this.$message({
              type: "success",
              message: "Loaded " + name,
            });
          } else {
            this.$message({
              type: "success",
              message: "Updated " + name + " Content Nodes",
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Failed to switch bot.`,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-header {
  position: absolute;
  right: 16px;
  top: -5px;
  z-index: 999;
}
</style>
