<template>
  <el-container
    v-if="selectedChat"
    v-loading="isLoading || uploading"
    :element-loading-text="loadingText"
    id="mainchat"
    :style="mainChatStyle"
  >
    <VisitorProfile
      v-if="dataCollectionFormEnabled"
      :style="visitorProfileStyle"
      :formData="selectedChatformData"
      ref="visitor-profile"
      class="visitorprofile"
      @toggleFormData="toggleFormData"
    />

    <el-header class="interactions-row" style="padding: 10px 10px 0 10px">
      <el-row
        type="flex"
        style="align-items: center; justify-content: space-between; flex-direction: row"
      >
        <div style="display: flex; flex-direction: row">
          <div>
            <span style="font-weight: bold">
              {{ getChatName(selectedChat) }}
              <el-tooltip
                effect="dark"
                content="Shows values in the following order: Full Name, Mobile Number, Email, Chat ID"
                placement="right"
              >
                <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="Browser notification was disabled"
                placement="right"
                v-if="!isNotificationEnable"
              >
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="mini"
                  round
                  icon="el-icon-close-notification"
                  @click="enableNotification"
                  >Enable Notification</el-button
                >
              </el-tooltip>
            </span>
            <FromNow
              style="font-size: 0.7em; color: grey; margin-bottom: 3px"
              :value="lastSentMessageTimeStamp"
              :interval="5000"
              :prefixMessage="'last update '"
            />
          </div>
        </div>

        <div style="display: flex; flex-direction: row; place-items: center; gap: 10px">
          <EscalationTimeline :chat="selectedChat" />
          <UserQueryTopicTimeline :chat="selectedChat" />

          <label
            v-if="!showUserDetails"
            style="float: right"
            class="el-tag el-tag--small el-tag--light"
            @click="showUserDetails = !showUserDetails"
          >
            Show User Profile
            <i class="el-icon-arrow-down el-icon--right"></i>
          </label>
          <label
            v-else-if="showUserDetails"
            style="float: right"
            class="el-tag el-tag--small el-tag--light"
            @click="showUserDetails = !showUserDetails"
          >
            Hide User Profile
            <i class="el-icon-arrow-up el-icon--right"></i>
          </label>

          <el-tooltip
            class="item"
            effect="dark"
            content="Click to start collecting livechat data"
            placement="top"
          >
            <el-button
              v-if="dataCollectionFormEnabled"
              id="flashing-button"
              style="float: right; margin-left: 0"
              :icon="collapseIcon"
              circle
              size="mini"
              @click="toggleFormData"
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Download selected chat data"
            placement="left"
          >
            <el-button
              style="float: right; margin-left: 0"
              icon="el-icon-download"
              circle
              size="mini"
              @click="exportChat"
            />
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Close this chat" placement="left">
            <el-button
              plain
              type="danger"
              style="float: right; margin-left: 0"
              icon="el-icon-close"
              circle
              size="mini"
              @click="closeCurrentChat"
            />
          </el-tooltip>
        </div>
      </el-row>

      <!-- Tags -->
      <Tags
        :state-variables="stateVariables"
        :selected-chat="selectedChat"
        :is-queue-tab="isQueueTab"
        :is-resolved-tab="isResolvedTab"
      />

      <el-row>
        <el-collapse-transition>
          <div v-show="showUserDetails">
            <el-divider></el-divider>
            <UserDetails :selected-chat="selectedChat" :value="userDetails" />
            <el-divider></el-divider>
          </div>
        </el-collapse-transition>
      </el-row>
    </el-header>

    <el-header
      v-if="isQueueTab || isMonitorTab"
      style="padding: 8px; vertical-align: center; height: auto"
      class="secondary-header"
    >
      <el-row style="margin-bottom: 0" type="flex">
        <template v-if="isQueueTab">
          <el-button
            v-if="!isInChat"
            type="warning"
            size="mini"
            style="margin-right: 10px"
            icon="el-icon-chat-dot-round"
            :disabled="agentIsNotOnline || isBusy"
            :loading="isJoiningChat"
            @click.native="joinLivechat(selectedChat)"
          >
            Start conversation
          </el-button>

          <template v-if="!dataCollectionFormEnabled" style="margin-right: 10px">
            <el-button
              plain
              type="primary"
              v-if="
                (isRoutingBroadcastMode && isInChat) ||
                (isRoutingAssignmentMode && hasMoreThanOneAgents)
              "
              size="mini"
              icon="el-icon-close"
              style="margin-right: 10px"
              :disabled="!canAgentReceiveNewMessage || isBusy"
              :loading="isLeavingChat"
              @click.native="leaveLivechat(selectedChat)"
            >
              Leave chat
            </el-button>

            <EscalateChat
              v-if="isHandoverRoutingByDepartmentEnabled"
              :is-busy="isBusy"
              :selected-chat="selectedChat"
              :is-in-chat="isInChat"
            />

            <el-button
              plain
              v-if="isInChat"
              size="mini"
              type="success"
              icon="el-icon-check"
              style="margin-right: 10px; margin-left: 0"
              :disabled="!canAgentReceiveNewMessage || isBusy"
              :loading="isResolvingChat"
              @click.native="setResolve"
              >Mark as resolved</el-button
            >
            <el-button
              plain
              v-if="isInChat && isCobrowsingEnabled && channelName === 'webchat'"
              size="mini"
              type="info"
              :icon="inCobrowseSession ? 'el-icon-phone-outline' : 'el-icon-phone'"
              style="margin-right: 10px; margin-left: 0"
              :disabled="isBusy || inCobrowseSession"
              @click.native="startAndConnectCobrowse()"
              >{{ inCobrowseSession ? "Ongoing Co-browsing..." : "Start Co-browsing" }}
            </el-button>
          </template>

          <FormEnabledStatus v-if="dataCollectionFormEnabled" />
          <InviteAgent :chats-list="chatsList" :selected-chat="selectedChat" :is-busy="isBusy" />
        </template>

        <el-button
          v-else-if="isMonitorTab"
          size="mini"
          type="warning"
          :disabled="agentIsNotOnline || isBusy || differentDepartment"
          :loading="isJoiningChat"
          @click.native="sendToQueue()"
        >
          Start conversation
        </el-button>
        <el-tooltip
          effect="dark"
          content="Disabled, if sending to queue in process, or in offline status, or different department with user"
          placement="right"
          style="margin-left: 4px"
        >
          <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
        </el-tooltip>
      </el-row>
    </el-header>

    <PurgeBanner />
    <el-header
      v-show="inCobrowseSession && cobrowseFrameUrl.includes(selectedChat.user_id)"
      class="secondary-header cobrowse-banner"
    >
      You are in ongoing Co-browsing session,
      <span @click="cobrowseDrawerOpen = true">click here</span>
      to open the session, or close this banner to stop
      <i @click="stopCobrowse()" class="el-icon-close"></i>
    </el-header>

    <!-- Messages of the chat -->
    <el-main ref="chatWindow" class="chat-window" style="padding-bottom: 30px">
      <div class="prev-conversation">
        <el-button
          plain
          class="more-conversation-button"
          v-if="showMoreConversationButton"
          :loading="isLoadingMoreConversation"
          @click="showMoreConversation(selectedChat)"
        >
          - show more conversations -
        </el-button>
      </div>

      <el-row
        style="margin-bottom: 0"
        v-for="message in selectedChatInteractions"
        :key="`message-${message.PartitionKey}-${message.RowKey}`"
      >
        <!-- {{message}} -->
        <!-- CHAT MESSAGE -->
        <div
          :style="{
            width: '50%',
            float: isMessageByUser(message.type) ? 'left' : 'right',
          }"
        >
          <el-alert
            v-if="isDisconnectMessage(message)"
            :title="message.data.content[0].text"
            :description="$store.state.showAdvanced ? `Reason: ${message.error}` : ''"
            style="margin-left: 20px; margin-bottom: 10px"
            type="error"
            effect="dark"
            show-icon
            :closable="false"
          />
          <el-alert
            v-else-if="isReconnectMessage(message)"
            :title="message.data.content[0].text"
            style="margin-left: 20px; margin-bottom: 10px"
            type="success"
            effect="dark"
            show-icon
            :closable="false"
          />
          <span v-else>
            <message
              :key="message.RowKey"
              :message="message"
              :has-more-than-one-agent-in-chat="hasMoreThanOneAgentInChat"
              font="Tahoma"
              agent-bubble-color="#F0F0F0"
              agent-text-color="#4b4f56"
              user-bubble-color="#0084FF"
              user-text-color="#fff"
              button-outline-color="black"
            />
          </span>
        </div>
      </el-row>

      <el-row>
        <el-col style="margin: 0px 25px 0px 25px">
          <img
            v-show="getTypingIndicator"
            src="../../assets/typing_indicator.gif"
            style="width: 30px"
          />
        </el-col>
      </el-row>
    </el-main>

    <div v-if="dataCollectionFormEnabled && !pendingOrAttendingStatus">
      <el-card
        class="livechatTextBoxSection"
        width="100%"
        shadow="never"
        body-style="border: 1px 0px"
      >
        <p class="has-text-dark" style="text-align: center">This chat has ended on user side</p>
      </el-card>
    </div>
    <el-row style="padding: 5px; margin-bottom: 0" v-else-if="isInChat" type="flex">
      <QuickReplies
        v-if="isLivechatQuickRepliesEnabled"
        :chat-interactions="selectedChatInteractions"
        :selected-chat-user-id="selectedChat.user_id"
        :selected-chat-partition-key="selectedChat.PartitionKey"
      />
      <el-autocomplete
        v-if="$store.state.modules.system.livechatReplySuggestions"
        type="textarea"
        style="margin-right: 5px; width: 100%; height: 100%; font-family: inherit"
        ref="textbox"
        class="autocompleteTextbox"
        v-model="replyMessage"
        placeholder="Start chatting..."
        popper-class="livechat-message-suggestion"
        :debounce="500"
        :autosize="{ minRows: 1, maxRows: 6 }"
        :fetch-suggestions="getShortcuts"
        :disabled="isBusy"
        @select="handleShortcutSelection"
        @keydown.enter.native.exact="handleSendEvent"
      >
        <!-- <el-input ref="agent-input" type="textarea" placeholder="message" v-if="isInChat" v-model="selectedChat.reply" @keydown.enter.native="handleTextarea"> -->
        <template slot-scope="props">
          <strong class="command">/{{ props.item.name }}</strong>
          <span>
            {{ Array.isArray(props.item.message) ? props.item.message[0] : props.item.message }}
          </span>
        </template>
        <!-- </el-input> -->
      </el-autocomplete>

      <textarea
        v-else
        type="textarea"
        style="margin-right: 5px; width: 100%"
        ref="textbox"
        class="message-textbox"
        v-model="replyMessage"
        placeholder="Start chatting..."
        :disabled="isBusy"
        @keydown.enter.exact="handleSendEvent"
      ></textarea>

      <el-button-group style="display: inherit">
        <el-tooltip class="item" effect="dark" placement="top-end">
          <div slot="content">
            Hit [⏎ Enter] to send message or [⇧ Shift] + [⏎ Enter] to add a new line to your message
          </div>
          <el-button
            type="primary"
            style="min-width: 120px"
            :disabled="isBusy"
            @click="handleSendEvent"
          >
            Send&nbsp;&nbsp;&nbsp;
            <i class="el-icon-s-promotion"></i>
          </el-button>
        </el-tooltip>
        <HelperActions
          v-if="isHelperActionsEnabled"
          :selected-chat-user-id="selectedChat.user_id"
          :selected-chat-partition-key="selectedChat.PartitionKey"
        />
        <el-button
          v-if="isUploadable"
          :disabled="!isInChat || isBusy || !canAgentReceiveNewMessage"
          icon="el-icon-document"
          type="primary"
          plain
          @click="fileUpload"
        />
      </el-button-group>

      <input
        id="upload"
        ref="upload"
        type="file"
        style="display: none"
        multiple
        accept=".xls, .xlsx, .doc, .docx, .dotx, application/msword, image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        @change="onFileUploadChange"
      />
    </el-row>

    <!-- File upload dialog -->
    <el-dialog
      title="File Upload confirmation"
      :visible.sync="fileUploadDialogVisible"
      :modal="false"
    >
      <span>These are the files you selected:</span>
      <el-main style="max-height: 300px">
        <el-row type="flex">
          <div v-for="(value, index) in confirmationFiles" :key="index" style="padding: 10px">
            {{ index + 1 }}. {{ value }}
            <br />
            <img
              v-if="isImageFile(value)"
              :src="confirmationFilesPreview[index]"
              style="height: 150px; width: auto"
            />
            <i v-else class="el-icon-document" style="font-size: 40px; padding: 20px 50px" />
          </div>
        </el-row>
      </el-main>
      <div class="error-message" v-if="fileValidationErrors">
        <p v-for="error in fileValidationErrors" :key="error">
          <i style="margin-right: 5px" class="el-icon-warning-outline" />
          {{ error }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileUploadDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmFileUpload" :disabled="!!fileValidationErrors">
          Confirm
        </el-button>
      </span>
    </el-dialog>

    <v-style v-if="isIE">
      .message-bubble-left:before { color: #0b93f6!important; border-left: 20px solid
      transparent!important; bottom:2px!important; } .message-bubble-left:after {
      background:transparent !important; } .message-bubble-right:before { color: #e5e5ea!important;
      border-right: 20px solid transparent!important; bottom:2px!important; }
    </v-style>

    <el-drawer
      title="Cobrowsing Session"
      :with-header="true"
      :visible.sync="inCobrowseSession"
      :append-to-body="true"
      direction="ttb"
      :modal="false"
      :size="!cobrowseDrawerOpen ? '0%' : '100%'"
      :custom-class="!cobrowseDrawerOpen ? 'cobrowse-drawer-hide' : ''"
      :wrapperClosable="false"
      :close-on-press-escape="false"
      :before-close="minimizeOrCloseCobrowseDrawer"
      ref="cobrowseDrawer"
    >
      <iframe focus="true" :src="cobrowseFrameUrl" height="100%" width="100%" frameborder="0" />
    </el-drawer>
  </el-container>
</template>

<script>
import FormEnabledStatus from "@/components/ChatInteractions/FormEnabledStatus/Index";
import VisitorProfile from "@/components/LiveChat/VisitorProfile";
import Message from "./Message/Index";
import FromNow from "@/components/LiveChat/DynamicFromNow";
import EscalationTimeline from "@/components/ChatInteractions/Timelines/EscalationTimeline";
import UserQueryTopicTimeline from "@/components/ChatInteractions/Timelines/UserQueryTopicTimeline";
import EscalateChat from "./EscalateChat/Index";
import InviteAgent from "./InviteAgent/Index";
import PurgeBanner from "./PurgeBanner/Index";
import Tags from "./Tags/Index";
import { listenGraphQLWebsocket, GRAPHQL_WEBSOCKET_STATE } from "@/store/api";
import LocalStorageManager from "@/localStorageManager";

import { prepareExcelText } from "@/helperMethods/livechat/export";
import { isFunction } from "@/helperMethods/util";
import {
  getAvatar,
  getLastSentMessageTimeStamp,
  isIEOrEdge,
  isMessageByUser,
} from "@/helperMethods/livechat/util";
import { validateFiles } from "@/helpers/validateFiles";
import {
  isNotificationEnable,
  requestPushNotificationPermission,
} from "@/helperMethods/pushNotification";
import { getChatName } from "@/filters";

import { mapGetters } from "vuex";
import _ from "lodash";
import QuickReplies from "./QuickReplies";
import HelperActions from "./HelperActions";
import UserDetails from "./UserDetails/Index";
import XLSX from "xlsx";
import Vue from "vue";
import { defaultOptionsXSS } from "@/helpers/xssHelper";
import { filterXSS } from "xss";
import { SYSTEM_MESSAGE } from "@/lib/viewConstants";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});

export default {
  name: "ChatsInteractions",
  components: {
    Message,
    VisitorProfile,
    FormEnabledStatus,
    FromNow,
    EscalateChat,
    InviteAgent,
    Tags,
    QuickReplies,
    HelperActions,
    PurgeBanner,
    UserDetails,
    EscalationTimeline,
    UserQueryTopicTimeline,
  },
  props: {
    chatsList: Array,
    selectedChat: Object,
    // selectedIndex: Number,
    selectedRowKey: String,
    isLoading: Boolean,

    closed: Boolean,
  },
  data() {
    return {
      userDetails: {
        name: "",
        alias: "",
        mobileNo: "",
        email: "",
        comments: "",
      },

      showUserDetails: false,
      isIE: false,
      formShowing: false,
      selectedTransferAgentEmail: "",
      inviteAgentDialogVisible: false,
      fileUploadDialogVisible: false,
      toDepartment: "",
      uploading: false,
      transferMemoInput: "",
      isUploadable: false,
      confirmationFiles: [],
      confirmationFilesPreview: [],
      MAX_TABLET_WIDTH: 1024,

      typingIndicatorStatus: false,

      replyMessage: "",

      isLoadingMoreConversation: false,
      showMoreConversationButton: true,

      isResolvingChat: false,
      isLeavingChat: false,
      isJoiningChat: false,
      isAgentTyping: false,

      canAgentReceiveNewMessage: true,
      fileValidationErrors: null,
      isNotificationEnable: false,

      // co browse
      cobrowseFrameUrl: "",
      inCobrowseSession: false,
      cobrowseDrawerOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      "dataCollectionFormEnabled",
      "showAdvanced",
      "activeTabName",
      "isQueueTab",
      "isResolvedTab",
      "isMonitorTab",
      "selectedChatId",
      "isAgentOnline",
      "isRoutingAssignmentMode",
      "isRoutingBroadcastMode",
      "getAgentSOEId",
      "getAgentName",
      "getTypingIndicator",
    ]),

    differentDepartment() {
      const userDepartments = _.get(this.$store, "state.profile.app_metadata.departments", []);
      const userDepartmentsWithoutGeneral = _.filter(userDepartments, (dept) => dept !== "general");
      const chatDepartment = _.get(this.selectedChat, "stateVariables.userQueryTopic");
      if (!chatDepartment || _.isEmpty(userDepartmentsWithoutGeneral)) {
        return false;
      }

      return !userDepartmentsWithoutGeneral.includes(chatDepartment);
    },

    isHelperActionsEnabled() {
      return _.get(this, "$store.state.modules.handover.liveChatHelperActions.enabled", false);
    },

    /**
     * @description Is handover by department enabled
     * @return {boolean}
     */
    isHandoverRoutingByDepartmentEnabled() {
      return _.get(this.$store.state, "modules.handover.handoverRouting.byDepartment", false);
    },

    /**
     * @description Has more than 1 agent in chat
     * @return {boolean}
     */
    hasMoreThanOneAgentInChat() {
      const agentsCounter = {};
      const chatInteractions = _.get(this.selectedChat, "interactions", []);
      chatInteractions.forEach((chat) => {
        if (chat.type !== "agent") {
          return;
        }
        const agent = _.get(chat, "meta.email");
        if (!agent) {
          return;
        }
        if (!agentsCounter[agent]) {
          agentsCounter[agent] = 1;
        }
        agentsCounter[agent]++;
      });

      const numberOfAgentsFromInteractions = Object.keys(agentsCounter).length;
      const numberOfAttendedAgents = _.get(this.selectedChat, "agents", []).length;

      return numberOfAgentsFromInteractions > 1 || numberOfAttendedAgents > 1;
    },

    /**
     * @description Has any ongoing request
     * @return {boolean}
     */
    isBusy() {
      return (
        this.isResolvingChat ||
        this.uploading ||
        this.isLoadingMoreConversation ||
        this.isJoiningChat ||
        this.isLeavingChat
      );
    },

    /**
     * @description Patched state variables
     * @return {any}
     */
    stateVariables() {
      const exluded = [
        "channel",
        "source",
        "session_history",
        "RowKey",
        "PartitionKey",
        "user_id",
        "source_wa_id",
      ];
      return _.chain(this.selectedChat)
        .get("stateVariables", {})
        .omit(exluded)
        .omitBy((variable) => _.isObject(variable) || _.isPlainObject(variable))
        .value();
    },

    selectedChatformData() {
      if (_.isEmpty(this.selectedChat)) {
        return null;
      }
      return Object.assign({}, this.selectedChat.formData, {
        agentName: this.getAgentName,
        agentSoeId: this.getAgentSOEId,
      });
    },
    visitorProfileStyle() {
      return this.formShowing ? { width: "500px" } : { width: "0px" };
    },
    mainChatStyle() {
      return this.formShowing ? { marginRight: "500px" } : { marginRight: "0" };
    },

    agentIsNotOnline() {
      return !this.isAgentOnline;
    },
    pendingOrAttendingStatus() {
      const selectChatStatus = this.selectedChat.status;
      const chatNotAttendedTo = selectChatStatus === "attending" || selectChatStatus === "pending";
      return chatNotAttendedTo;
    },

    collapseIcon() {
      let icon = "el-icon-arrow-left";
      if (this.formShowing) {
        icon = "el-icon-arrow-right";
      }
      return icon;
    },
    replyFileMessage: {
      get: function () {
        const isObjectReplyFile = typeof _.get(this, "selectedChat.replyFile") === "object";
        if (isObjectReplyFile) {
          return this.selectedChat.replyFile;
        } else {
          return {};
        }
      },
      set: function (newValue) {
        if (this.selectedChat) {
          Vue.set(this.selectedChat, "replyFile", newValue);
        }
      },
    },
    loadingText() {
      return !this.uploading ? "Loading selected chat..." : "Uploading files ...";
    },
    selectedChatInteractions() {
      const interactions = _.chain(this.selectedChat)
        .get("interactions", [])
        .uniqBy((interaction) => interaction.RowKey)
        .orderBy(["date_created"], ["asc"])
        .value();
      return interactions;
    },
    lastSentMessageTimeStamp() {
      const lastTimestamp = getLastSentMessageTimeStamp(
        this.selectedChat,
        this.selectedChatInteractions
      );
      return lastTimestamp;
    },
    isInChat() {
      if (this.isResolvedTab || this.isMonitorTab) {
        return false;
      } else {
        const agents = _.get(this, "selectedChat.agents", []).map((email) => email.toLowerCase());
        return agents.includes(this.$store.state.profile.email?.toLowerCase());
      }
    },

    hasMoreThanOneAgents() {
      const agents = this.selectedChat.agents || [];
      const hasMoreThanOneAgents = agents.length > 1;
      return hasMoreThanOneAgents;
    },

    shortcuts() {
      return _.map(this.$store.state.nodes.content, (content, nodeId) => {
        const appSource = _.chain(content)
          .get("conditions", [])
          .filter((o) => o.property === "appSource")
          .map((o) => o.value)
          .value();
        const departments = _.get(content, "department", ["general"]);
        return {
          name: nodeId,
          message: content.content.text,
          options: "",
          tags: [],
          appSource,
          departments,
        };
      });
    },

    isCobrowsingEnabled() {
      const cobrowseEnabled = _.get(this, "$store.state.modules.handover.cobrowse.enabled");
      const cobrowseApiKey = _.get(this, "$store.state.modules.handover.cobrowse.apiKey");
      return cobrowseEnabled && cobrowseApiKey;
    },
    channelName() {
      const channel =
        _.get(this.selectedChat, "stateVariables.channel", this.selectedChat.channel) || "unknown";
      return channel;
    },
    filteredXSSMessage() {
      const textBeforeXSSFilter = this.replyMessage.trim();
      return filterXSS(textBeforeXSSFilter, defaultOptionsXSS);
    },
    isLivechatQuickRepliesEnabled() {
      const result = _.get(this, "$store.state.modules.system.livechatQuickReplies.enabled", false);
      return result;
    },
  },
  methods: {
    getChatName,
    enableNotification() {
      requestPushNotificationPermission(
        () => {
          this.isNotificationEnable = true;
          this.$message({
            message: "Browser notification was enabled",
            type: "success",
          });
        },
        () => {
          this.isNotificationEnable = false;
          this.$message({
            message: "Enable browser notification was not successful.",
            type: "warning",
          });
        }
      );
    },
    handleShortcutSelection(selection) {
      const message = Array.isArray(selection.message) ? selection.message[0] : selection.message;
      this.replyMessage = message;
    },

    getShortcuts(query, callback) {
      if (!query || query.charAt(0) !== "/") {
        callback([]);
        return;
      }

      query = query.substr(1);
      const numberToDisplay = 10;
      const queryLowerCase = query.toLowerCase();
      // get app source for current active chat
      const activeChatAppSourceFromUserQuery = _.get(
        this.selectedChat,
        "stateVariables.userQueryTopic"
      );
      const activeChatAppSourceFromTags = _.chain(this.selectedChat)
        .get("tags")
        .find((tag) => tag.includes("appSource:"))
        .split(":")
        .get("[1]", null)
        .value();
      const activeChatAppSource = _.chain([
        activeChatAppSourceFromUserQuery,
        activeChatAppSourceFromTags,
      ])
        .compact()
        .uniq()
        .value();

      // get suggestion filter settings and user department for filtering purpose
      const checkDepartment = _.get(
        this.$store.state.modules,
        "system.livechatReplySuggestionsFilter",
        false
      );
      const activeChatDepartments = this.$store.getters.userDepartment.length
        ? this.$store.getters.userDepartment
        : ["general"];

      // filter available shortcuts to fit in required condition
      const shortcuts = _.chain(this.shortcuts)
        .filter((shortcut) => {
          const message = _.flatten([shortcut.message])[0];
          const shortcutAppSource = shortcut.appSource;
          const shortcutDepartments = _.uniq([...shortcut.departments, "general"]);

          const appSourceIsMatch = !(
            activeChatAppSource.length &&
            shortcutAppSource.length &&
            _.chain(shortcutAppSource).intersection(activeChatAppSource).isEmpty().value()
          );
          const departmentIsMatch = checkDepartment
            ? !_.chain(shortcutDepartments).intersection(activeChatDepartments).isEmpty().value() ||
              (shortcutDepartments.length === 1 && shortcutDepartments[0] === "general")
            : true;

          return (
            appSourceIsMatch &&
            departmentIsMatch &&
            `/${shortcut.name} ${message}`.toLowerCase().includes(queryLowerCase)
          );
        })
        .take(numberToDisplay)
        .value();

      // pass the return value in callback function
      // ref: https://element.ele.me/#/en-US/component/input#autocomplete
      callback(shortcuts);
    },
    getAvatar,
    isIEOrEdge,
    isMessageByUser,
    resetFormShowingAndButtons() {
      this.formShowing = false;
      const flashingButton = document.getElementById("flashing-button");

      if (flashingButton) {
        flashingButton.className.replace(/\bnotification-flashing\b/g, "");
      }
    },
    toggleFormData() {
      const formNotShowing = !this.formShowing;

      this.formShowing = formNotShowing;
    },
    handleSendEvent(event) {
      event.preventDefault();
      this.reply();
    },
    isDisconnectMessage(message) {
      return (
        message.error &&
        message.data.content &&
        message.data.content.length === 1 &&
        message.data.content[0].text
      );
    },
    isReconnectMessage(message) {
      return (
        message.data.content &&
        message.data.content.length === 1 &&
        message.data.content[0].text &&
        message.data.content[0].text.toLowerCase() === SYSTEM_MESSAGE.connected
      );
    },
    isImageFile(url) {
      const imageRegex = new RegExp("(jpg|jpeg)|(png)", "gi");
      return imageRegex.test(url);
    },
    confirmFileUpload() {
      this.fileUploadDialogVisible = false;
      this.updateUploadStatus(true);
      const files = this.event.target.files;

      let filesUrls = [];
      if (files) {
        const fileNames = [];

        // Must use FormData for IE or Edge.
        let formData = new FormData();
        const isIEOrEdge = this.isIEOrEdge();
        for (let i = 0; i < files.length; i++) {
          fileNames.push(files[i].name);
          if (isIEOrEdge) {
            formData.append(`files[${i}]`, files[i]);
          }
        }

        this.$store
          .dispatch("REQUEST_SAS", { fileNames })
          .then((sasUrls) => {
            // Upload to temp blob
            // fileUrls = sasUrls;
            this.$store
              .dispatch("UPLOAD_FILE", {
                sasUrls,
                formData,
                files,
                isIEOrEdge,
                rowKey: this.selectedChat.user_id,
              })
              .then(async (response) => {
                // getting the tempurls without the tokens.
                const imageUrls = [];
                const nonImageUrls = [];
                sasUrls.map((url, index) => {
                  const imageRegex = new RegExp("(jpg|jpeg)|(png)", "gi");
                  const newUrl = url.slice(0, url.indexOf("?"));

                  if (imageRegex.test(newUrl)) {
                    imageUrls.push(newUrl);
                  } else {
                    nonImageUrls.push(newUrl);
                  }
                  return;
                });

                const responseFromCeph = _.isEmpty(sasUrls);
                if (responseFromCeph && response) {
                  response.map((url, index) => {
                    const imageRegex = new RegExp("(jpg|jpeg)|(png)", "gi");
                    const newUrl = url.slice(url.lastIndexOf("/"), url.length);
                    if (imageRegex.test(newUrl)) {
                      imageUrls.push(url);
                    } else {
                      nonImageUrls.push(url);
                    }
                    return;
                  });
                }

                if (imageUrls.length > 0) {
                  this.replyFileMessage = { images: imageUrls };
                  await this.reply();
                }

                if (nonImageUrls.length > 0) {
                  this.replyFileMessage = { files: nonImageUrls };
                  await this.reply();
                }

                this.updateUploadStatus(false);
                this.$refs.upload.value = [];

                // In IE 11, the dialog will reappear
                this.fileUploadDialogVisible = false;
              })
              .catch((err) => {
                this.$refs.upload.value = [];
                this.updateUploadStatus(false);
                this.$notify.error({
                  title: "Error",
                  message: "An error occurred while uploading file!",
                  position: "bottom-right",
                });
              });
          })
          .catch((err) => {
            this.$refs.upload.value = [];
            this.updateUploadStatus(false);
            this.$notify.error({
              title: "Error",
              message: "An error occurred while uploading file!",
              position: "bottom-right",
            });
          });
      }
    },
    isFileUploadable() {
      const isFileUploadEnabled = this.$store.state.modules.webchat.fileUpload;

      // FIXME: WILL BE DEPRECATED @DHONI
      // const blob = new Blob([JSON.stringify({ hello: "world" })], {
      //   type: "application/json",
      // });

      // const testFile = !this.isIEOrEdge()
      //   ? new File([blob], "test_cors.json")
      //   : blob;

      if (!isFileUploadEnabled) {
        this.isUploadable = false;

        return;
      }

      this.isUploadable = true;
      // FIXME: no need to verify cors allow origin
      // this.$store.dispatch("GET_STORAGE_HOST").then((data) => {
      //   const url = new URL(data.primary).origin;

      //   this.$store
      //     .dispatch("VERIFY_CORS_SAS_URL", { url })
      //     .then((response) => {
      //       this.isUploadable = true;
      //     })
      //     .catch((error) => {
      //       this.isUploadable = false;
      //     });

      // FIXME: @Dhoni To be deprecated!
      // // Upload to temp blob
      // this.$store
      //   .dispatch("UPLOAD_FILE", {
      //     sasUrls,
      //     event: "file_upload",
      //     files: [testFile],
      //     text: "File Upload",
      //   })
      //   .then(async (response) => {
      //     // display file as message to client
      //     this.isUploadable = true;
      //   })
      //   .catch((error) => {
      //     this.isUploadable = false;
      //   });
      // });
    },

    updateUploadStatus(uploadStatus = false) {
      this.uploading = uploadStatus;
    },

    generateExcel(chat, tabName = "") {
      const keysToBeExcluded = [
        "livechat_data",
        "session_history",
        "headers",
        "temp",
        "private_variables",
        "ip",
        "interactions",
      ];
      let wb = XLSX.utils.book_new();
      const ws_data = prepareExcelText(chat, keysToBeExcluded);
      let ws = XLSX.utils.aoa_to_sheet(ws_data);

      const sheetName = _.get(chat, "RowKey", "").substring(0, 15);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const filename = this.$exportFilename(`chat_conversations_${tabName}`, "xlsx");
      XLSX.writeFile(wb, filename, {});

      this.$notify.success({
        title: "Download Success",
        message: "Successfully downloaded selected chat",
        position: "bottom-right",
      });
    },
    exportChat() {
      const isQueueTab = this.isQueueTab;
      const isResolvedTab = this.isResolvedTab;
      const isMonitorTab = this.isMonitorTab;

      if (isMonitorTab) {
        this.exportMonitorChat();
        return;
      }

      if (isResolvedTab) {
        this.exportResolvedChat();
        return;
      }

      if (isQueueTab) {
        this.exportQueueChat();
        return;
      }
    },
    exportQueueChat() {
      const currentChat = _.cloneDeep(this.selectedChat);
      const actionToBeCalled = "GET_SESSION_BY_USER_ID_AND_PARTITION_KEY";
      const paramsToBePassed = {
        userId: currentChat.user_id,
        sessionPartitionKey: currentChat.PartitionKey,
        isResolved: false,
      };

      this.$store
        .dispatch(actionToBeCalled, paramsToBePassed)
        .then((chat) => {
          this.generateExcel(chat, "queue");
        })
        .catch((err) => {
          let message = "Encountered error exporting chat";
          if (this.showAdvanced) {
            message = err.message;
          }
          this.$notify.error({
            title: "Error",
            message,
            position: "bottom-right",
          });
        });
    },
    exportResolvedChat() {
      const currentChat = _.cloneDeep(this.selectedChat);
      this.generateExcel(currentChat, "resolved");
    },
    exportMonitorChat() {
      const currentChat = _.cloneDeep(this.selectedChat);

      // monitor export will only fetch more livechat interactions to be exported
      this.$store
        .dispatch("FETCH_MORE_MONITOR_LIVECHAT_INTERACTION", {
          partitionKey: currentChat.PartitionKey,
          userId: currentChat.RowKey,
          limit: 1000,
          offset: 0,
        })
        .then((interactions) => {
          const currentInteractions = currentChat.interactions;
          const newInteractions = _.concat(currentInteractions, interactions);
          const newChat = Object.assign({}, currentChat, {
            interactions: newInteractions,
          });

          this.generateExcel(newChat, "monitor");
        })
        .catch((err) => {
          let message = "Encountered error exporting chat";
          if (this.showAdvanced) {
            message = err.message;
          }
          this.$notify.error({
            title: "Error",
            message,
            position: "bottom-right",
          });
        });
    },
    joinLivechat: _.throttle(
      function (chatToJoin) {
        this.isJoiningChat = true;
        const agentId = this.$store.state.profile.email;
        this.$store
          .dispatch("CHECK_LIVECHAT_AVAILABILITY", {
            chat: chatToJoin,
            agentId,
          })
          .then((response) => {
            if (!response.success) {
              this.$notify.error({
                title: "Error",
                message: response.reason,
                position: "bottom-right",
              });

              this.selectedChat = null;
              return;
            }

            return this.dispatchJoinLivechat(chatToJoin);
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              message: "Encountered error checking for chat's availability",
              position: "bottom-right",
            });
          })
          .finally(() => {
            this.isJoiningChat = false;
          });
      },
      1000,
      { leading: true }
    ),
    dispatchJoinLivechat(chatToJoin) {
      const agentId = this.$store.state.profile.email;
      return this.$store
        .dispatch("JOIN_LIVECHAT", {
          chat: chatToJoin,
          agentId,
        })
        .then((response) => {
          if (!response.success) {
            this.$notify.error({
              title: "Error",
              message: response.reason,
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: "Encountered error joining chat",
            position: "bottom-right",
          });
        });
    },
    leaveLivechat: _.throttle(
      function (chatToJoin) {
        this.isLeavingChat = true;
        const agentId = this.$store.state.profile.email;
        this.$store
          .dispatch("CHECK_RESOLVED_LIVECHAT", {
            chat: chatToJoin,
            agentId,
          })
          .then((response) => {
            if (!response.success) {
              this.$notify.error({
                title: "Error",
                message: response.reason,
                position: "bottom-right",
              });

              this.selectedChat = null;
              return;
            }

            return this.dispatchLeaveLivechat(chatToJoin);
          })
          .catch((err) => {
            this.$notify.error({
              title: "Error",
              message: "Encountered error checking resolved livechat",
              position: "bottom-right",
            });
          })
          .finally(() => {
            this.isLeavingChat = false;
          });
      },
      1000,
      { leading: true }
    ),
    dispatchLeaveLivechat(chatToJoin) {
      const agentId = this.$store.state.profile.email;
      return this.$store
        .dispatch("LEAVE_LIVECHAT", {
          chat: chatToJoin,
          agentId,
        })
        .then((chat) => {
          if (chat) {
            this.backToList();
          }
        });
    },
    backToList() {
      this.$emit("update:closed", false);
    },
    setResolve() {
      if (!this.isResolvingChat) {
        this.isResolvingChat = true;
        const chat = this.selectedChat; //required because selectedChat cannot be used for PERSIST_LIVECHAT after RESOLVE_LIVECHAT
        const resolveChatPayload = {
          sessionPartitionKey: _.get(chat, "PartitionKey", ""),
          userId: chat.user_id,
          sessionRowKey: chat.RowKey,
        };
        this.$store
          .dispatch("RESOLVE_LIVECHAT", resolveChatPayload)
          .then((isResolved) => {
            if (isResolved) {
              // handle this on QueueChatsTab so that if customer trigger the resolve agent will get notify.
              // this.$notify.success({
              //   title: "Success",
              //   message: "Resolved chat successfully",
              //   position: "bottom-right"
              // });
              this.$emit("update-assigned", "");
            } else {
              this.$notify.error({
                title: "Error",
                message: "Encountered error resolving chat",
                position: "bottom-right",
              });
            }
          })
          .catch((error) => {
            this.$notify.error({
              title: "Error",
              message: "Encountered error resolving chat",
              position: "bottom-right",
            });
          })
          .finally(() => {
            this.isResolvingChat = false;
            this.$store.commit("SET_ACTIVE_TAB_NAME", "resolved");
          });
        // this.$emit("update:selectedIndex", 0);
        this.$emit("update:selectedRowKey", "");
        this.backToList();
      }
    },
    sendToQueue() {
      this.isJoiningChat = true;
      this.$store
        .dispatch("SEND_TO_LIVECHAT_QUEUE", {
          user: this.selectedChat,
          agentEmail: this.$store.state.profile.email,
        })
        .then((response) => {
          const { isCreated, latestSession } = response;
          if (isCreated) {
            this.$notify.success({
              title: "Success",
              message: "User has been sent to queue successfully",
              position: "bottom-right",
            });
          } else {
            const agents = _.get(latestSession, "agents", []);
            let warningMessage;
            if (_.isEmpty(agents)) {
              warningMessage = `User is already in queue. Go to Queue tab to talk to user.`;
            } else {
              const agentNames = _.join(agents, ", ");
              warningMessage = `User is already in queue, being attended by agents: ${agentNames}.`;
            }

            this.$notify.warning({
              title: "Warning",
              message: warningMessage,
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error sending to livechat queue",
          });
        })
        .finally(() => {
          this.isJoiningChat = false;
        });
    },
    // File upload related functions
    fileUpload() {
      this.$refs.upload.click();
    },
    onFileUploadChange(event) {
      this.fileUploadDialogVisible = true;

      this.event = event;
      const files = event.target.files;

      this.fileValidationErrors = null;
      const { isValid, errors } = validateFiles(files);
      if (!isValid) {
        this.fileValidationErrors = errors;
      }

      this.confirmationFilesPreview = _.map(files, (item, index) => {
        return URL.createObjectURL(item);
      });

      this.confirmationFiles = _.map(files, (item, index) => {
        return item.name;
      });
    },
    async reply(chat = this.selectedChat) {
      const isEmptyMessage = this.filteredXSSMessage.length === 0;
      const hasImages = this.replyFileMessage.images;
      const hasFiles = this.replyFileMessage.files;
      const source = _.get(chat, "stateVariables.source", "webchat");
      const reply = {
        page_id: chat.PartitionKey,
        user_id: chat.user_id,
        isUser: false,
        isResolved: false,
        source,
      };

      // Attach message payload
      if (hasImages) {
        reply.images = this.replyFileMessage.images;
      } else if (hasFiles) {
        reply.files = this.replyFileMessage.files;
      } else {
        if (isEmptyMessage) {
          this.$notify.warning({
            message: "You cannot send a blank message.",
            position: "bottom-right",
          });

          return;
        } else {
          reply.text = this.filteredXSSMessage;
        }
      }
      this.replyMessage = "";
      LocalStorageManager.removeLivechatDraft(
        this.$store.state.profile.email,
        this.selectedChat.user_id
      );
      this.replyFileMessage = {};
      this.$nextTick(this.scrollToBottom);

      return this.sendMessage(reply)
        .then(() => {
          this.$forceUpdate();
        })
        .catch((error) => {
          this.$notify.error({
            title: "Cannot send message",
            message: "Please check the internet connection and refresh this page.",
            position: "bottom-right",
          });
        });
    },
    sendMessage: _.debounce(
      function (reply) {
        return this.$store.dispatch("SEND_MESSAGE", { reply });
      },
      500,
      { leading: true }
    ),
    scrollToBottom() {
      if (this.$refs.chatWindow) {
        const container = this.$refs.chatWindow.$el;
        container.scrollTop = container.scrollHeight;
      }
    },

    scrollToTop() {
      if (this.$refs.chatWindow) {
        const container = this.$refs.chatWindow.$el;
        container.scrollBottom = container.scrollHeight;
      }
    },

    getFetchMorePayload(chatObject) {
      // Get the current size of the interactions, send it for pagination size
      const offset = _.chain(chatObject).get("interactions", []).size().value();
      const userId = chatObject.RowKey;
      const partitionKey = chatObject.PartitionKey;

      // Fetch 20 at a time
      const fetchMorePayload = { userId, partitionKey, limit: 20, offset };
      return fetchMorePayload;
    },

    // Show more chat messages
    showMoreConversation(chatObject) {
      const fetchMorePayload = this.getFetchMorePayload(chatObject);

      const isQueueTab = this.activeTabName === "queue";
      const isResolvedTab = this.activeTabName === "resolved";
      const isAbandonedTab = this.activeTabName === "abandoned";
      const isMonitorTab = this.activeTabName === "monitor";

      if (isQueueTab) {
        return this.fetchMoreQueueChatMessages(fetchMorePayload);
      } else if (isResolvedTab) {
        return this.fetchMoreResolvedChatMessages(fetchMorePayload);
      } else if (isAbandonedTab) {
        return this.fetchMoreAbandonedChatMessages(fetchMorePayload);
      } else if (isMonitorTab) {
        return this.fetchMoreMonitorChatMessages(fetchMorePayload);
      }
    },
    fetchMoreQueueChatMessages(fetchMorePayload) {
      this.isLoadingMoreConversation = true;
      return this.$store
        .dispatch("FETCH_MORE_LIVECHAT_INTERACTION", fetchMorePayload)
        .then((newInteractions) => {
          const fetchedAll = newInteractions.length === 0;
          if (fetchedAll) {
            this.showMoreConversationButton = false;
          }

          const currentInteractions = this.selectedChat.interactions;
          const updatedInteractions = _.concat(newInteractions, currentInteractions);
          const updatedLivechat = Object.assign({}, this.selectedChat, {
            interactions: updatedInteractions,
          });
          this.$store.commit("UPDATE_QUEUE_CHAT", { updatedLivechat });
          this.$nextTick(this.scrollToTop);
          this.isLoadingMoreConversation = false;
        })
        .catch((err) => {
          this.isLoadingMoreConversation = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching queued chat history",
            position: "bottom-right",
          });
        });
    },
    fetchMoreResolvedChatMessages(fetchMorePayload) {
      this.isLoadingMoreConversation = true;
      return this.$store
        .dispatch("FETCH_MORE_LIVECHAT_INTERACTION", fetchMorePayload)
        .then((newInteractions) => {
          const fetchedAll = newInteractions.length === 0;
          if (fetchedAll) {
            this.showMoreConversationButton = false;
          }

          const currentInteractions = this.selectedChat.interactions;
          const updatedInteractions = _.concat(currentInteractions, newInteractions);
          const updatedLivechat = Object.assign({}, this.selectedChat, {
            interactions: updatedInteractions,
          });
          this.$store.commit("UPDATE_RESOLVED_CHAT", { updatedLivechat });
          this.$nextTick(this.scrollToTop);
          this.isLoadingMoreConversation = false;
        })
        .catch((error) => {
          this.isLoadingMoreConversation = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching resolved chat history",
            position: "bottom-right",
          });
        });
    },
    fetchMoreAbandonedChatMessages(fetchMorePayload) {
      this.isLoadingMoreConversation = true;
      return this.$store
        .dispatch("FETCH_MORE_LIVECHAT_INTERACTION", fetchMorePayload)
        .then((newInteractions) => {
          const fetchedAll = newInteractions.length === 0;
          if (fetchedAll) {
            this.showMoreConversationButton = false;
          }

          const currentInteractions = this.selectedChat.interactions;
          const updatedInteractions = _.concat(currentInteractions, newInteractions);
          const updatedLivechat = Object.assign({}, this.selectedChat, {
            interactions: updatedInteractions,
          });
          this.$store.commit("UPDATE_ABANDONED_CHAT", { updatedLivechat });
          this.$nextTick(this.scrollToTop);
          this.isLoadingMoreConversation = false;
        })
        .catch((error) => {
          this.isLoadingMoreConversation = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching abandoned chat history",
            position: "bottom-right",
          });
        });
    },
    fetchMoreMonitorChatMessages(fetchMorePayload) {
      this.isLoadingMoreConversation = true;
      this.$store
        .dispatch("FETCH_MORE_MONITOR_LIVECHAT_INTERACTION", fetchMorePayload)
        .then((newInteractions) => {
          const fetchedAll = newInteractions.length === 0;
          if (fetchedAll) {
            this.showMoreConversationButton = false;
          }

          const currentInteractions = this.selectedChat.interactions;
          const updatedInteractions = _.concat(currentInteractions, newInteractions);
          const updatedLivechat = Object.assign({}, this.selectedChat, {
            interactions: updatedInteractions,
          });

          this.$store.commit("UPDATE_MONITOR_CHAT", { updatedLivechat });
          this.$nextTick(this.scrollToTop);
          this.$notify.success({
            title: "Success",
            message: "Fetched past messages successfully",
            position: "bottom-right",
          });
          this.isLoadingMoreConversation = false;
        })
        .catch((error) => {
          this.isLoadingMoreConversation = false;
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching monitor chat history",
            position: "bottom-right",
          });
        });
    },
    setWebChatTypingIndicator(isTyping) {
      if (this.isAgentTyping !== isTyping) {
        // Only execute this if not same status.
        this.isAgentTyping = isTyping;
        this.$store.dispatch("TOGGLE_WEBCHAT_TYPING_INDICATOR", {
          chat: this.selectedChat,
          isTyping,
        });
      }
    },
    closeCurrentChat() {
      this.$store.commit("SET_SELECTED_CHAT_ID", null);
      this.$store.commit("SELECT_MONITOR_CHAT", {
        partitionKey: null,
        rowKey: null,
        type: "monitor",
      });
    },
    async startAndConnectCobrowse() {
      this.startCobrowse();
      const userId = this.selectedChat.user_id;
      const agent = this.$store.state.profile.email;
      const { url } = await this.$rest("get", `webchat_cobrowse?user=${userId}&agent=${agent}`);
      this.cobrowseFrameUrl = url;
    },
    toggleCobrowse(status) {
      const chat = this.selectedChat;
      this.$store
        .dispatch("TOGGLE_COBROWSE", {
          chat,
          status,
        })
        .then(() => {
          this.inCobrowseSession = status;
          this.cobrowseDrawerOpen = status;
        })
        .catch(() => {
          this.inCobrowseSession = false;
          this.cobrowseDrawerOpen = false;
        });
    },
    startCobrowse() {
      if (!this.inCobrowseSession) {
        this.toggleCobrowse(true);
      }
    },
    stopCobrowse() {
      if (this.inCobrowseSession) {
        this.toggleCobrowse(false);
      }
    },
    minimizeOrCloseCobrowseDrawer(done) {
      this.$confirm("Are you sure want to stop co-browse session?", "Confirmation", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Yes, stop",
        cancelButtonText: "No, minimize",
        showClose: false,
      })
        .then(() => {
          this.stopCobrowse();
          done();
        })
        .catch(() => {
          this.cobrowseDrawerOpen = false;
        });
    },
  },
  mounted() {
    this.replyMessage = LocalStorageManager.getLivechatDraft(
      this.$store.state.profile.email,
      this.selectedChat.user_id
    );
    if (isNotificationEnable()) {
      this.isNotificationEnable = true;
    }
    if (this.selectedChat?.userDetails) {
      this.userDetails = {
        ...this.selectedChat.userDetails,
      };
    }

    this.isIE = this.isIEOrEdge();
    this.$nextTick(this.scrollToBottom);

    this.unsubcribleWSReconnectedEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.RECONNECTED,
      () => {
        this.canAgentReceiveNewMessage = true;
      }
    );
    this.unsubcribleWSErrorEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.ERROR,
      (error) => {
        this.canAgentReceiveNewMessage = false;
      }
    );
    this.unsubcribleWSDisconnectedEvent = listenGraphQLWebsocket(
      GRAPHQL_WEBSOCKET_STATE.DISCONNECTED,
      () => {
        this.canAgentReceiveNewMessage = false;
      }
    );
  },
  beforeDestroy() {
    isFunction(this.unsubcribleWSReconnectedEvent) && this.unsubcribleWSReconnectedEvent();
    isFunction(this.unsubcribleWSErrorEvent) && this.unsubcribleWSErrorEvent();
    isFunction(this.unsubcribleWSDisconnectedEvent) && this.unsubcribleWSDisconnectedEvent();
  },
  beforeMount() {
    _.delay(() => this.isFileUploadable(), 100);
  },
  watch: {
    selectedChat(selectedChat, previousChat) {
      const selectedChatId = _.get(selectedChat, "RowKey");
      const previousChatId = _.get(previousChat, "RowKey");
      if (selectedChatId === previousChatId) {
        return;
      }

      const hasVisitorProfileRendered = _.has(this.$refs, "visitor-profile");
      const hasSelectedChatfFormData = !_.isEmpty(this.selectedChatformData);
      const canSetFormData =
        hasVisitorProfileRendered && hasSelectedChatfFormData && this.dataCollectionFormEnabled;

      if (canSetFormData) {
        this.$refs["visitor-profile"].selectedFormData = this.selectedChatformData;
      }

      this.showMoreConversationButton = true;

      if (selectedChat) {
        this.$nextTick(this.scrollToBottom);
      }

      this.replyMessage = LocalStorageManager.getLivechatDraft(
        this.$store.state.profile.email,
        this.selectedChat?.user_id
      ); // Reset replyMessage whenever a selectedChat changes

      if (this.selectedChat?.userDetails) {
        this.userDetails = {
          ...this.selectedChat.userDetails,
        };
      }
    },
    selectedChatId: "resetFormShowingAndButtons",
    replyMessage: _.throttle(
      function (updatedMessage) {
        LocalStorageManager.updateLivechatDraft(
          this.$store.state.profile.email,
          this.selectedChat.user_id,
          updatedMessage
        );
        if (!this.selectedChat) {
          this.setWebChatTypingIndicator(false);
          return;
        }

        if (_.isEmpty(updatedMessage)) {
          this.setWebChatTypingIndicator(false);
        } else {
          this.setWebChatTypingIndicator(true);
        }
      },
      1500,
      { leading: true }
    ),
    cobrowseDrawerOpen(val) {
      if (val) {
        const drawerParent = this.$refs.cobrowseDrawer.$el;
        if (drawerParent) {
          drawerParent.className = drawerParent.className.replace(
            " cobrowse-drawer-parent-hide",
            ""
          );
        }
      } else {
        setTimeout(() => {
          const drawerParent = this.$refs.cobrowseDrawer.$el;
          if (drawerParent) {
            drawerParent.className += " cobrowse-drawer-parent-hide";
          }
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

@keyframes flashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.911;
  }
}

.notification-flashing {
  background-color: $color-warning;
  will-change: opacity;
  animation: flashing 1500ms 3.5 forwards;
}

.prev-conversation {
  text-align: center;
  cursor: pointer;
}
.more-conversation-button {
  border: 0px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}
.interactions-row {
  height: auto !important;
}

@media (min-width: 580px) and (max-width: 1024px) {
  .el-main {
    /* max-height: 55%; */
    overflow: scroll;
  }
  .el-dropdown-menu__item {
    font-size: 1.125em !important;
  }
}

.visitorprofile {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 10;
  overflow: auto;
  transition: 0.3s;
  right: 0;
}

#mainchat {
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  border: 0;
}

.chat-window {
  padding: 15px 0 0 0;
  overflow-x: hidden;
  border-top: 1px solid $color-light;
}

.secondary-header {
  border-top: 1px solid $color-light;
}

.message-textbox {
  min-height: 40px !important;
  padding: 8px 15px;
  font-family: inherit;
  border-radius: 5px;
  color: inherit;
}

.message-textbox:focus {
  outline: none !important;
  border: 1px solid $color-primary;
}

.autocompleteTextbox textarea {
  min-height: 40px !important;
  padding: 8px 15px;
}

.el-card.livechatTextBoxSection .el-card__body {
  padding: 5px !important;
}

.el-card.livechatTextBoxSection {
  border-radius: 0px;
  background-color: $color-light;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
}

.error-message {
  color: $color-danger;
}

.el-drawer__wrapper {
  height: 500px;
  width: 100%;
  top: 0;
  left: 0;
}

.cobrowse-banner {
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  vertical-align: center;
  height: auto !important;
  background-color: $color-warning;
  color: #fff;

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  i {
    cursor: pointer;
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s;
  }
  i:hover {
    transition: 0.7s;
    transform: rotate(180deg);
  }
}

#mainchat {
  ::v-deep .el-loading-mask {
    z-index: 10;
  }
}
</style>

<style>
.livechat-message-suggestion li {
  white-space: normal;
}

.livechat-message-suggestion {
  left: 0px;
  width: 99% !important;
  position: absolute;
  bottom: 45px;
  z-index: 10;
  max-width: 100%;
  max-height: 200px;
}

.cobrowse-drawer-hide {
  display: none;
}

.cobrowse-drawer-parent-hide {
  height: 0 !important;
  width: 0 !important;
}
</style>
