<template>
  <el-table
    :border="true"
    :show-header="false"
    :data="arrayApis"
    highlight-current-row
    ref="api-settings-table"
    size="mini"
    @row-click="showActiveApi"
  >
    <el-table-column prop="name" label="Name">
      <template slot-scope="scope">
        <el-tag
          style="width: 56px; margin-right: 8px; text-align: center"
          size="mini"
          effect="plain"
          :type="
            {
              GET: 'success',
              POST: 'warning',
              DELETE: 'danger',
              PUT: 'info',
            }[scope.row.method]
          "
        >
          {{ scope.row.method }}
        </el-tag>
        {{ scope.row.name }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: ["apiLoading", "arrayApis"],
  data() {
    return {
      dialogVisible: false,
      selectedDeleteApiName: "",
    };
  },
  methods: {
    showDeleteApiDialog(props) {
      this.$emit("show-delete-api-dialog", props);
    },
    showActiveApi(api) {
      this.$emit("show-api-editor", api.name);
    },
  },
};
</script>
