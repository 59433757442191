<template>
  <div class="node-container" :class="{ 'container-after': index < length - 1 }">
    <el-card
      :class="{
        'box-card': displayNode && title === node.dialog_node,
        'border-green': node.matched === 'full',
        'border-orange': node.matched === 'child',
      }"
    >
      <div
        class="node-left-container"
        :class="{
          'border-right':
            node.type === 'folder' ||
            (childNodes[node.dialog_node] && childNodes[node.dialog_node].length > 0),
        }"
        @click="showChild = !showChild"
      >
        <div class="node-left">
          <i v-if="node.type === 'folder'" class="ion-ios-folder-outline" />
          <i
            v-if="
              node.type !== 'folder' &&
              childNodes[node.dialog_node] &&
              childNodes[node.dialog_node].length > 0
            "
            :class="{
              'el-icon-arrow-right': !showChild,
              'el-icon-arrow-down': showChild,
            }"
          />
        </div>
      </div>
      <div class="node-item" @click="nodeClick(node)">
        <p>{{ node.title || "no title" }}</p>
        <span class="time">{{ node.conditions }}</span>
      </div>
      <div class="node-right">
        <el-popover placement="bottom" width="160" trigger="click">
          <div class="popover-item menu-button" @click="addChild(node)">
            <i class="el-icon-back" style="transform: rotate(180deg); margin-right: 10px" />Add
            child node
          </div>
          <div class="popover-item menu-button" @click="addAbove(node)">
            <i class="el-icon-back" style="transform: rotate(90deg); margin-right: 10px" />
            Add node above
          </div>
          <div class="popover-item menu-button" @click="addBelow(node)">
            <i class="el-icon-back" style="transform: rotate(-90deg); margin-right: 10px" />Add node
            below
          </div>
          <div class="popover-item menu-button" @click="addFolder(node)">
            <i class="el-icon-message" style="margin-right: 10px" />Add folder
          </div>
          <div class="popover-item menu-button" @click="deleteNode(node)">
            <i class="el-icon-delete" style="margin-right: 10px" />Delete
          </div>
          <i slot="reference" class="el-icon-more-outline" style="cursor: pointer" />
        </el-popover>
      </div>
    </el-card>

    <template v-for="(cnode, index2) in childNodes[node.dialog_node]">
      <template v-if="node.dialog_node === cnode.parent && showChild">
        <DialogNode
          :key="cnode.dialog_node + index2"
          :length="childNodes[node.dialog_node].length"
          style="margin-left: 50px"
          :child-nodes="childNodes"
          :title="title"
          :node="cnode"
          :index="index2"
          :display-node="displayNode"
          @node-clicked="nodeClick"
          @addChild-click="addChild"
          @addAbove-click="addAbove"
          @addBelow-click="addBelow"
          @addFolder-click="addFolder"
          @delete-click="deleteNode"
        />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "DialogNode",
  props: {
    node: Object,
    displayNode: Boolean,
    index: Number,
    title: String,
    childNodes: Object,
    length: Number,
  },
  data() {
    return {
      showChild: false,
      showPopover: false,
    };
  },
  computed: {},
  mounted() {},

  methods: {
    nodeClick(node) {
      this.$emit("node-clicked", node);
    },
    addChild(node) {
      this.$emit("addChild-click", node);
    },
    addAbove(node) {
      this.$emit("addAbove-click", node);
    },
    addBelow(node) {
      this.$emit("addBelow-click", node);
    },
    addFolder(node) {
      this.$emit("addFolder-click", node);
    },
    deleteNode(node) {
      this.$emit("delete-click", node);
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.node-container {
  position: relative;
}

.menu-button:hover {
  background-color: lighten($color-primary, 45);
  cursor: pointer;
}

.node-container::before {
  content: "";
  display: block;
  border-bottom: 2px solid #c0bfc0;
  border-left: 2px solid #c0bfc0;
  border-bottom-left-radius: 50%;
  width: 22px;
  height: 47px;
  left: -24px;
  top: 0;
  padding: 0;
  position: absolute;
}

.container-after::after {
  content: "";
  position: absolute;
  display: block;
  border-left: 2px solid #c0bfc0;
  width: 0;
  height: 100%;
  left: -24px;
  top: 16px;
}

.border-green {
  border-left: 4px solid #64c42f !important;
}

.border-orange {
  border-left: 4px solid #f0c783 !important;
}
</style>
