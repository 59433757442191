<template>
  <div class="livechat-container">
    <div class="livechat-container--top">
      <SearchChatFilters @resetResolvedChatFilters="reset" tab="resolved" />
    </div>
    <div class="livechat-container--left">
      <div class="chat-list--header">
        <span>{{ resolvedBadgeNumber || 0 }} Resolved Chats</span>
      </div>
      <div class="chat-list--wrapper">
        <div class="chat-list--main">
          <ResolvedChatsList
            @fetchMoreResolvedChats="fetchMoreResolvedChats"
            :selected-index.sync="selectedIndex"
            :show-more-is-loading="showMoreIsLoading"
            :has-no-more-chats="hasNoMoreChats"
          />
        </div>
      </div>
    </div>
    <div class="livechat-container--right">
      <ChatsInteractions
        v-if="selectedChat"
        :chats-list="resolvedChatsArray"
        :selected-chat="selectedChat"
        :selected-index.sync="selectedIndex"
        :is-loading="fetchResolvedChatLoading"
        :closed.sync="closed"
      />
      <el-row v-if="$store.state.showAdvanced">
        <JSONEditor v-model="selectedChat" />
      </el-row>
    </div>
  </div>
</template>

<script>
import SearchChatFilters from "./Filter.vue";
import ResolvedChatsList from "./List.vue";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";
import FETCH_RESOLVED_CHATS_QUERY from "@/graphql/LIVECHATS_GET_RESOLVED.gql";
import { getAvatar } from "@/helperMethods/livechat/util";
import _ from "lodash";
import { mapGetters } from "vuex";
import { splitAbandonedResolvedChats } from "@/helperMethods/livechat/splitAbandonedResolvedChats";

export default {
  name: "ChatsTab",
  components: {
    SearchChatFilters,
    ResolvedChatsList,
    ChatsInteractions,
    JSONEditor,
  },
  props: { resolvedChatLoading: Boolean },
  data() {
    return {
      selectedIndex: 0,
      closed: false,
      type: { name: "resolved" },
      hasNoMoreChats: false,
      showMoreIsLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "fetchResolvedChatLoading",
      "resolvedChatTags",
      "resolvedChatsArray",
      "unfilteredResolvedChatsArray",
      "resolvedChatFilters",
      "resolvedBadgeNumber",
      "selectedChatId",
      "selectedChat",
    ]),
  },

  methods: {
    getAvatar,
    reset() {
      this.$store.commit("FLUSH_RESOLVED_CHATS_ARRAY");
      this.hasNoMoreChats = false;
    },
    fetchMoreResolvedChats() {
      this.showMoreIsLoading = true;
      const filters = _.cloneDeep(this.resolvedChatFilters);
      filters.offset = this.unfilteredResolvedChatsArray.length;
      this.$store.commit("UPDATE_RESOLVED_LIVECHAT_FILTERS", filters);
    },
  },
  apollo: {
    resolvedChats: {
      query: FETCH_RESOLVED_CHATS_QUERY,
      variables() {
        return this.resolvedChatFilters;
      },
      fetchPolicy: "network-only",
      update(result) {
        const newResolvedSessions = _.get(result, "livechatAPI.resolvedSessions", []);

        if (newResolvedSessions.length < this.resolvedChatFilters.limit) {
          this.hasNoMoreChats = true;
        }

        const { resolvedChats, abandonedChats } = splitAbandonedResolvedChats(newResolvedSessions);
        this.$store.commit("SET_RESOLVED_CHATS_ARRAY", {
          resolvedChatsArray: resolvedChats,
          unfilteredResolvedChatsArray: newResolvedSessions,
        });
        // this.$store.commit("SET_ABANDONED_CHATS_ARRAY", {
        //   abandonedChatsArray: abandonedChats,
        // });
        this.$store.commit("SET_FETCH_RESOLVED_CHAT_LOADING", false);
        this.$store.commit("SET_FETCH_ABANDONED_CHAT_LOADING", false);
        this.showMoreIsLoading = false;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/tab.scss";
</style>
